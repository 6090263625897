<template>
  <div>
    <table :id="id" aria-label="oversigt over fremskrivninger" class="table w-percent-100">
      <thead>
      <tr v-if="tableHeaders" class="bg-primary text-color-white">
        <th
            v-for="(tableHeader, index) in tableHeaders"
            :id="`table-header-${index}-${id}`"
            :key="tableHeader.sortKey"
            class="clickable"
            scope="col"
            @click="sort(tableHeader.sortKey)"
        >
          <div class="nowrap">
          {{ tableHeader.headline }}
          <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
            <path
                :fill="currentSort === tableHeader.sortKey && currentSortDir === 'asc' ? 'white' : ''"
                clip-rule="evenodd"
                d="M9.99419 4.75003L6.00003 0.755859L2.00586 4.75003H9.99419Z"
                fill-rule="evenodd"
                stroke="white"
            />
            <path
                :fill="currentSort === tableHeader.sortKey && currentSortDir === 'desc' ? 'white' : ''"
                clip-rule="evenodd"
                d="M2.00586 7.25003L6.00003 11.2442L9.99419 7.25003H2.00586Z"
                fill-rule="evenodd"
                stroke="white"
            />
          </svg>
          </div>
        </th>
        <th>
          <simple-text text-key='forecast.overview.menuitem.reduction' />
        </th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="sortedForecasts.length === 0 || !sortedForecasts">
        <td :headers="getRowDataHeaderDefinition(id)" class="table-no-content" colspan="5"><SimpleText :textKey="'forecast.overview.no-items-in-list'"/></td>
      </tr>
      <tr v-for="forecast in sortedForecasts" :key="forecast.forecastId" :data-cy-name="forecast.name">
        <td :headers="`table-header-0-${id}`" data-title="Navn" style="width: 25%">
          {{ forecast.name }}
          <span v-if="showPending(forecast)" class="badge badge-small badge-info"><SimpleText :text-key="'common.calculating'" /></span>
          <span v-if="showFailed(forecast)" class="badge badge-small badge-error"><SimpleText :text-key="'forecast.resultview.errorcalculatingforecast'" /></span>
        </td>
        <td :headers="`table-header-1-${id}`" data-title="Basis beregning" style="width: 25%">
          {{ forecast.sourceCalculationName }}
        </td>
        <td :headers="`table-header-2-${id}`" data-title="Basis år" class="align-text-right" style="width: 10%">
          {{ forecast.basisYear }}
        </td>
        <td :headers="`table-header-3-${id}`" data-title="Vækstrate" class="align-text-right" style="width: 10%">
          {{ forecast.growthRate % 1 !== 0 ? doPrettify(forecast.growthRate, 2) : forecast.growthRate }}%
        </td>
        <td :headers="`table-header-4-${id}`" data-title="Væksttype" style="width: 10%">
          {{ findGrowthTypeShortName(forecast.growthType) }}
        </td>
        <td style="width: 30%">
          <strong><simple-text text-key='forecast.overview.tableheader.scope12' /></strong><br />
          <i><simple-text text-key='forecast.overview.tableheader.method' />: </i>
          <template v-if='forecast.reductionInfoScope12'>
            {{ methodContentfulName(String(forecast.reductionInfoScope12.method)) }}
          </template>
          <template v-else>
            <i>
              <simple-text text-key='forecast.overview.table.not-assigned' />
            </i>
          </template>
          <br />
          <i><simple-text text-key='forecast.overview.tableheader.target' />: </i>
          <template v-if='forecast.reductionInfoScope12 && forecast.reductionInfoScope12.targetPctInput !== undefined'>
            {{ formatPercentage(forecast.reductionInfoScope12.targetPctInput) }}
          </template>
          <template v-else>
            -
          </template>
          <br />
          <strong><simple-text text-key='forecast.overview.tableheader.scope3' /></strong> <br />
          <i><simple-text text-key='forecast.overview.tableheader.method' />: </i>
          <template v-if='forecast.reductionInfoScope3'>
            {{ methodContentfulName(String(forecast.reductionInfoScope3.method)) }}
          </template>
          <template v-else>
            <i>
              <simple-text text-key='forecast.overview.table.not-assigned' />
            </i>
          </template>
          <br />
          <i><simple-text text-key='forecast.overview.tableheader.target' />: </i>
          <template v-if='forecast.reductionInfoScope3 && forecast.reductionInfoScope3.targetPctInput !== undefined'>
            {{ formatPercentage(forecast.reductionInfoScope3.targetPctInput) }}
          </template>
          <template v-else>
            -
          </template>
        </td>
        <td :headers="`table-header-4-${id}`" data-title="Handlinger">
          <div class="overflow-menu overflow-menu--open-left">
            <button
                :class="`overflow-menu-table`"
                :data-js-target="`forecast-${forecast.forecastId}-actions-overflow`"
                aria-expanded="false"
                aria-haspopup="true"
                aria-label="handlinger"
                class="button-overflow-menu js-dropdown "
            >
              <svg aria-hidden="true" class="icon-svg" fill="white" focusable="false">
                <use xlink:href="#more-vert"></use>
              </svg>
            </button>
            <div :id="`forecast-${forecast.forecastId}-actions-overflow`" aria-hidden="true" class="overflow-menu-inner">
              <!-- List of possible events to apply to a forecast -->
              <ul class="overflow-list">
                <li>
                  <router-link class="edit-button" to="/forecast/calculate-forecast" @click="$emit('edit-forecast', forecast)">
                    <SimpleText :textKey="'forecast.overview.menuitem.open'"/>
                    <svg aria-hidden="true" class="icon-svg ml-3" focusable="false">
                      <use xlink:href="#mode"></use>
                    </svg>
                  </router-link>
                </li>
                <li>
                  <button class="delete-button" @click="$emit('delete-forecast', forecast)">
                    <SimpleText :textKey="'forecast.overview.menuitem.delete'"/>
                    <svg aria-hidden="true" class="icon-svg ml-3" focusable="false">
                      <use xlink:href="#trash-can"></use>
                    </svg>
                  </button>
                </li>
                <li>
                  <a class="reduction-button" @click="$emit('reduction-forecast', forecast)">
                    <SimpleText :textKey="'forecast.overview.menuitem.reduction'"/>
                    <svg aria-hidden="true" class="icon-svg ml-3" focusable="false">
                      <use xlink:href="#chevron-double-right"></use>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import TableHeader from '@/types/TableHeader'
import * as DKFDS from 'dkfds'
import { ForecastInfo, ReductionInfo } from 'openapi/generated-clients/climatecompass'
import BaseComponent from '@/components/calculator/base-components/BaseComponent'
import SimpleText from '@/components/SimpleText.vue'
import { prettify } from '@/shared/PrettifyNumbers'

export interface CombinedForecastReduction extends ForecastInfo {
  reductionInfoScope12?: ReductionInfo
  reductionInfoScope3?: ReductionInfo
}

@Options({
  emits: ["delete-forecast", "edit-forecast", "reduction-forecast"],
  components: {
    SimpleText
  }
})
export default class ForecastOverviewTableComponent extends BaseComponent {
  @Prop({required: true})
  forecasts: CombinedForecastReduction[] = []

  @Prop({required: true})
  id?: string

  @Prop({required: true})
  pendingForecastIds? : number[] = []

  tableHeaders = [] as TableHeader[]
  currentSort = "name"
  currentSortDir = "asc"
  formatPercentage(percentage: number | undefined) {
    return percentage !== undefined ? prettify(percentage, 2) + '%' : '-'
  }
  methodContentfulName(method: string) {
    switch (method) {
      case 'manual':
        return this.getContentfulString('forecast.reduction-target.field.method.option.manual.name')
      case 'sbti':
        return this.getContentfulString('forecast.reduction-target.field.method.option.sbti.name.short')
      default:
        return this.getContentfulString('forecast.overview.table.not-assigned')
    }
  }

  getRowDataHeaderDefinition(id: any) {
    return 'table-header-0-' + id + ' ' + 'table-header-1-' + id + ' ' + 'table-header-2-' + id + ' ' + 'table-header-3-' + id + ' ' + 'table-header-4-' + id
  }

  showPending(forecast: ForecastInfo) {
    if(forecast.forecastId && this.pendingForecastIds) {
      if(this.pendingForecastIds.length > 0) {
        return this.pendingForecastIds.includes(forecast.forecastId)
      } else {
        return !forecast.isCreationCompleted
      }

    }
    return false
  }

  showFailed(forecast: ForecastInfo) {
    if(forecast.forecastId) {
      return forecast.message && forecast.messageStatus !== 'UNKNOWN'
    }
    return false
  }

  get sortedForecasts() {
    if (this.forecasts) {
      const _forecasts = [...this.forecasts.filter(this.isFiltered)]

      if (this.currentSort === "basis") {
        return _forecasts.sort((a, b) => {
          let modifier = 1
          if (this.currentSortDir === "desc") {
            modifier = -1
          }
          if (a.sourceCalculationName && b.sourceCalculationName && a.sourceCalculationName! < b.sourceCalculationName!) {
            return -1 * modifier
          } else if (a.sourceCalculationName && b.sourceCalculationName && a.sourceCalculationName! > b.sourceCalculationName!) {
            return modifier
          }
          return 0
        })
      }
      if (this.currentSort === "name") {
        return _forecasts.sort((a, b) => {
          let modifier = 1
          if (this.currentSortDir === "desc") {
            modifier = -1
          }
          if (a.name && b.name) {
            return a.name.localeCompare(b.name, "da-DK") * modifier
          } else {
            return modifier
          }
        })
      }
      if (this.currentSort === "year") {
        return _forecasts.sort((a, b) => {
          let modifier = 1
          if (this.currentSortDir === "desc") {
            modifier = -1
          }
          if (a.basisYear && b.basisYear && a.basisYear! < b.basisYear!) {
            return -1 * modifier
          } else if (a.basisYear && b.basisYear && a.basisYear! > b.basisYear!) {
            return modifier
          }
          return 0
        })
      }
      if (this.currentSort === "rate") {
        return _forecasts.sort((a, b) => {
          let modifier = 1
          if (this.currentSortDir === "desc") {
            modifier = -1
          }
          if (a.growthRate && b.growthRate && a.growthRate! < b.growthRate!) {
            return -1 * modifier
          } else if (a.growthRate && b.growthRate && a.growthRate! > b.growthRate!) {
            return modifier
          }
          return 0
        })
      }
      if (this.currentSort === "type") {
        return _forecasts.sort((a, b) => {
          let modifier = 1
          if (this.currentSortDir === "desc") {
            modifier = -1
          }
          if (a.growthType && b.growthType && a.growthType! < b.growthType!) {
            return -1 * modifier
          } else if (a.growthType && b.growthType && a.growthType! > b.growthType!) {
            return modifier
          }
          return 0
        })
      }
      return _forecasts.sort((a, b) => {
        let modifier = 1
        if (this.currentSortDir === "desc") {
          modifier = -1
        }
        return modifier
      })
    } else {
      return 0
    }
  }

  isFiltered(forecastInfo: ForecastInfo) {
    return true
  }

  getKeyValue = (key: string) => (obj: Record<string, any>) => obj[key]

  mounted() {
    DKFDS.init()
  }

  updated() {
    DKFDS.init()
  }

  async beforeMount() {
    this.tableHeaders = [
      {headline: this.getContentfulString('forecast.overview.table.header.name'), sortKey: "name", id: "name"},
      {headline: this.getContentfulString('forecast.overview.table.header.basis-calculation-name'), sortKey: "basis", id: "basis"},
      {headline: this.getContentfulString('forecast.overview.table.header.basis-calculation-year'), sortKey: "year", id: "year"},
      {headline: this.getContentfulString('forecast.overview.table.header.growth-rate'), sortKey: "rate", id: "rate"},
      {headline: this.getContentfulString('forecast.overview.table.header.growth-type'), sortKey: "type", id: "type"}
    ]
  }

  sort(sortBy: string) {
    if (sortBy === this.currentSort) {
      this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc"
    }
    this.currentSort = sortBy
  }

  findGrowthTypeShortName(growthType: string): string {
    return this.getContentfulString('forecast.calculate-forecast.field.type-growth-rate.option.' + growthType + '.short-name')
  }

}
</script>

<style lang="scss" scoped>

.clickable {
  cursor: pointer;
}

.table {
  border: 2px solid $color-primary;
}

.table th {
  border-right-color: white;
}

.table th,
.table td {
  border-bottom: 1px solid $color-primary;
}

.table th:nth-last-child(2),
.table td:nth-last-child(2) {
  border-right: none;
}

.table th:last-child,
.table td:last-child {
  color: white;
  background-color: $color-primary;
  border-right: none;
  border-bottom: 1px solid white;
  width: 1rem;
  padding: 8px 0;
}

.overflow-menu .button-overflow-menu {
  min-width: 0;
  min-height: 0;
  padding: 0;
}

.location .button-overflow-menu {
  text-decoration: underline;
}

.location .button-overflow-menu:focus {
  font-weight: bold;
}

.overflow-menu-inner li {
  text-align: left;
  padding: 0.25rem 1rem;
}

.overflow-menu-inner {
  overflow-y: auto;
  max-height: 40rem;
}

.disabled {
  opacity: 0.3;
}

#table-header-2-drafts {
  text-align: right;
}

#table-header-3-drafts {
  text-align: right;
}

</style>
