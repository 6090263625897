<template>
  <Toast
    v-if='!isTestFramework'
    :header='this.sessionExpirationTitle'
    :message='this.sessionExpirationText'
    :show='showExpirationNotification'
    :showCloseButton='showCloseButtonToast1'
    :type="'info'"
    name='fromCompTheHeaderToast1'
  >
    <template v-slot:footer>
      <br />
      <p>
        <SimpleText text-key='shared.your.session.expires.at' />
        <strong>{{ sessionExpirationTime }}</strong>
      </p>
      <br />
      <button id='login-refresh-button' class='button button-secondary d-print-none' @click='refreshLogin()'>
        <SimpleText text-key='sharedContent.refreshSessionButtonText' />
      </button>
      <button id='logout-notification-button' class='button button-secondary d-print-none' @click='logout()'>
        <SimpleText text-key='sharedContent.logout' />
      </button>
    </template>
  </Toast>

  <Toast
    v-if='getContactPerson.messages'
    :header='this.popupMessageTitle'
    :message='this.popupMessageText'
    :show='getContactPerson.messages'
    :showCloseButton='showCloseButtonToast2'
    :type="'info'"
    name='fromCompTheHeaderToast2'
    @toastOnClose='clearMessages()'
  >
    <template v-slot:footer>
      <br />
      <p v-if="getContactPerson.messages != 'sharedContent.popupMessageText'">
        {{ getContactPerson.messages }}
      </p>
      <br />
    </template>
  </Toast>

  <header class='header d-print-none'>
    <!-- Start: Portal header -->
    <div class='portal-header'>
      <div class='container portal-header-inner px-0'>
        <!-- Start: Portallogo -->
        <router-link class='logo' to='/'>
          <span class='logo__name h1'>{{ getContentfulString('application.main-title') }}</span>
          <span class='d-none d-print-block'>start</span>
          <span class='sr-only'>start</span>
        </router-link>
        <!-- Slut: Portallogo -->
        <!-- Start: Persondetaljer -->
        <div class='portal-info'>
          <div v-if='isAuthenticated'>
            <div v-if='thirdpartyInfo.behalfOfVatNr' class='on-behalf-of'>
              <svg aria-hidden='true' class='icon-svg' focusable='false'>
                <use xlink:href='#info'></use>
              </svg>
              <div class='on-behalf-of-body' v-html='behalfOfText'></div>
            </div>
          </div>
          <div v-if='isAuthenticated'>
            <!--{{getContactPerson.value.messages}}-->
            <p v-if='getCurrentUser.profile' class='user text-color-white'>
              <span class='weight-semibold'>
                {{ getCurrentUser.profile.name }}
              </span>
              <br />
              <span class='text-color-white'>
                {{ getCurrentUser.profile['dk:gov:org_name'] || '' }}
              </span>
            </p>
            <p v-if='thirdpartyInfo.behalfOfVatNr' class='user text-color-white'>
              <a style='cursor: pointer;'
                 @click='confirmEndAction()'><u>{{ this.getContentfulString('thirdparty.has-access-to.terminate-accesss.button')
                }}</u>&nbsp;&nbsp;</a>
              <svg style='stroke-width:1; stroke:white; fill:white' aria-hidden='true' class='icon-svg'
                   focusable='false'>
                <use xlink:href='#folder-multiple'></use>
              </svg>
            </p>
            <p v-if='thirdpartyInfo.behalfOfVatNr && this.determineLegitimateAccesses(givenAccessInfos)' class='user text-color-white'>
              <a data-cy-id="anchor_change_access" style='cursor: pointer;'
                 @click='initChangeAccess()'><u>{{ this.getContentfulString('thirdparty.has-access-to.change-access.button')
                }}</u>&nbsp;&nbsp;</a>
              <svg style='stroke-width:1; stroke:white; fill:white' aria-hidden='true' class='icon-svg'
                   focusable='false'>
                <use xlink:href='#people-alt'></use>
              </svg>
            </p>
          </div>

          <button v-if='!isAuthenticated && showSSILogin' id='login-button' class='button button-secondary d-print-none'
                  @click='loginPopup()'>
            <SimpleText text-key='sharedContent.login' />
          </button>
          <button v-if='!isAuthenticated && !showSSILogin' id='login-button' class='button button-secondary d-print-none'
                  @click='login()'>
            <SimpleText text-key='sharedContent.login' />
          </button>
          <!--button v-if='!isAuthenticated' id='login-button' class='button button-secondary d-print-none'
                  @click='loginPopup()'>
            Log ind (Popup)
          </button-->
          <button v-if='isAuthenticated' id='logout' class='button button-secondary d-print-none' @click='logout()'>
            <SimpleText text-key='sharedContent.logout' />
          </button>
        </div>
        <!-- Slut: Persondetaljer -->
      </div>
    </div>

    <!-- Slut: Portal header -->

    <!-- Start: Hovedmenu -->
    <div v-if='showNavigation' class='navbar navbar-primary text-color-white'>
      <div class='navbar-inner container px-0'>
        <ul class='nav-primary' role='menu'>
          <li v-for='menuItem in menuLive.menuItems' :key='menuItem.label' role='presentation'>
            <router-link
              v-if='isPrivateUser ? menuItem.showToPrivateUser : true'
              :id='`${menuItem.id}-link`'
              :exact='menuItem.exact'
              :to='menuItem.link'
              active-class='current'
              class='nav-link'
              role='menuitem'
              @click="menuItem.link === '/calculator' ? newCalculation() : ''"
              :data-cy-inner='menuItem.labelKey'
            >
              <span>
                {{ menuItem.label }}
              </span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- Slut: Hovedmenu -->
  </header>
  <!-- Delete confirmation modal START-->
  <template v-if='showModalDialog'>
    <div aria-hidden='false' class='fds-modal'>
      <div class='modal-content'>
        <div class='modal-header'>
          <h2 class='modal-title'>
            <SimpleText text-key='thirdparty.has-access-to.endActionText' />
          </h2>
          <button aria-label='Luk' class='modal-close button button-unstyled' @click='cancelEndAction()'>
            <svg aria-hidden='true' class='icon-svg' focusable='false'>
              <use xlink:href='#close'></use>
            </svg>
          </button>
        </div>
        <div class='modal-body'>
          <p> {{ endActionText }} </p>
        </div>

        <div class='modal-footer'>
          <router-link class='button button-primary' data-cy-id='modal-confirm' to='/' @click='endAction'>
            <SimpleText text-key='thirdparty.has-access-to.endActionText' />
          </router-link>
          <button class='button button-secondary' data-cy-id='modal-cancel' @click='cancelEndAction()'>
            <SimpleText text-key='thirdparty.has-access-to.cancelButton' />
          </button>
        </div>
      </div>
    </div>
    <div id='modal-backdrop' class='modal-backdrop' />
  </template>
  <template v-if='showChangeAccessModal'>
    <div aria-hidden='false' class='fds-modal'>
      <div class='modal-content' style='max-width: 1000px'>
        <div class='modal-header'>
          <h2 class='modal-title'>
            <SimpleText text-key='thirdparty.has-access-to.change-access.button' />
          </h2>
          <button aria-label='Luk' class='modal-close button button-unstyled' @click='cancelChangeAccess()'>
            <svg aria-hidden='true' class='icon-svg' focusable='false'>
              <use xlink:href='#close'></use>
            </svg>
          </button>
        </div>
        <div class='modal-body'>
            <h3><simple-text text-key='menu.setupmenu.item.showaccess'></simple-text></h3>
            <p v-html='documentToHtmlString(changeAccessModalDescription)'></p>
          <div class='row change-access-item' v-for='(access) in accessInfos.filter(x => ((x.expireDate > currentDate && !x.revoked && x.vatId != thirdpartyInfo.behalfOfVatNr) || (!x.expireDate && !x.revoked && x.vatId != thirdpartyInfo.behalfOfVatNr)) && (x.individualsAccessInfoList && x.individualsAccessInfoList.some(individual => individual.status?.toString()=== "ACCESS_GRANTED")))' :key='access.id'>
      <div class='col-4'>
                <fieldset role='radiogroup'>
                  <div class='form-group-radio'>
                    <input type='radio' :id="'radiogroup-access-' + access.id" :data-cy-id="access.vatIdSource" name='access-group'
                           class='form-radio radio-large' value='false'>
                    <label class='form-label' :for="'radiogroup-access-' + access.id">{{ access.privateSource ? privateUserText : access.companyNameSource }}</label>
                  </div>
                </fieldset>
              </div>
              <div class='col-4'>
                <span><SimpleText text-key='thirdparty.has-access-to.labelCvr'></SimpleText>: {{ access.privateSource ? cvrIsPrivateText : access.vatId }}</span>
              </div>
              <div class='col-4'>
                <span>{{ access.contantPersonName }}, <a
                  :href="'mailto:' + access.contactPersonEmail">{{ access.contactPersonEmail }}</a></span>
              </div>
            </div>
        </div>

        <div class='modal-footer'>
          <router-link class='button button-primary' data-cy-id='modal-confirm' to='/' @click='changeAccess()'>
            <SimpleText text-key='thirdparty.has-access-to.change-access.button' />
          </router-link>
          <button class='button button-secondary' data-cy-id='modal-cancel' @click='cancelChangeAccess()'>
            <SimpleText text-key='thirdparty.has-access-to.cancelButton' />
          </button>
        </div>
      </div>
    </div>
    <div id='modal-backdrop' class='modal-backdrop' />
  </template>
  <!-- Delete confirmation modal END-->
  <!-- copy confirmation modal START-->
  <template v-if="loginPressed">
    <div aria-hidden="false" class="fds-modal">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title"><SimpleText text-key="login.popup.title"/></h2>
          <button aria-label="Luk" class="modal-close button button-unstyled" @click="closeLoginDialog()">
            <svg aria-hidden="true" class="icon-svg" focusable="false">
              <use xlink:href="#close"></use>
            </svg>
          </button>
        </div>
        <div class="modal-body">
          <button data-cy-id="modal-login" @click="loginNormal()"  class="svg-button">
            <img src="~@/assets/mitid_logo.svg" alt="MitID login" class="icon"><SimpleText text-key="login.popup.button.normal.title"/>
          </button>
          <br><br>
          <SimpleText text-key="login.popup.description"/>
          <br><br>
          <h3 class="modal-title"><SimpleText text-key="login.popup.ssi.title"/></h3>
          <p><SimpleText text-key="login.popup.ssi.description"/></p>
          <button class="link-button" data-cy-id="modal-login-ssi" @click="loginSSI()" ><SimpleText text-key="login.popup.button.ssi.title"/>
          </button>
        </div>
      </div>
    </div>
    <div id="modal-backdrop" class="modal-backdrop"/>
  </template>

  <MultipleToast
    identifier='news-toast'
    v-show='isAuthenticated && showNewsOnPage()'
    ref='newsToast'
    @multipleToastClose='setShown($event)' />
</template>

<script lang='ts'>
import { getPage } from '@/shared/ContentfulService'
import { Menu } from '@/shared/MenuStructure'
import { useStore } from 'vuex'
import router from '@/router'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import Toast from '../Toast.vue'
import {
  accessClient,
  configurationClient,
  contactPersonClient,
  forecastClient,
  newsClient,
    notificationClient,
} from '@/shared/BackendService'
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { Cloneable } from '@/shared/DeepCopyHelper'
import { useRouter } from 'vue-router'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import NewsHeader from '@/types/contentful-types/NewsHeader'
import MultipleToast from '@/components/MultipleToast.vue'
import ToastMessage from '@/types/ToastMessage'
import { isAuthenticated } from '@/store/getters'
import { PendingForecastEntry } from '@/types/PendingForecastEntry'
import { AxiosResponse } from 'axios'
import {
  AccessInfo,
  ForecastResults,
  Notification,
} from '../../../openapi/generated-clients/climatecompass'
import { getCookie } from '@/shared/CookieHelper'
import { formatDate } from '@/shared/DateFormatHelper'
import SimpleText from '@/components/SimpleText.vue'
import ThirdpartyInfo from '@/types/ThirdpartyInfo'
import { login } from '@/store/mutations'


dayjs.extend(duration)
dayjs.extend(relativeTime)

@Options({
  components: {
    MultipleToast,
    Toast,
    SimpleText
  }
})
export default class TheHeader extends Vue {
  router = useRouter()
  newsProperties = {} as NewsHeader
  eventHub = require('tiny-emitter/instance')
  menu: Menu = {} as Menu
  hasConfigAccess = false
  showExpirationNotification = false
  sessionExpirationTime: any = ''
  store = useStore()
  showCloseButtonToast1 = false
  showCloseButtonToast2 = true
  showModalDialog = false
  showChangeAccessModal = false
  toastType = 'success'
  showToast = false
  accessInfos: AccessInfo[] = []
  thirdPartyInfo = {} as ThirdpartyInfo
  loginPressed = false
  showSSILogin = false

  forecastPollingTimeout = 30000 //milliseconds
  notificationPollingInterval = 60000 //milliseconds
  $refs!: {
    newsToast: MultipleToast
  }

  determineLegitimateAccesses(accessInfo : AccessInfo) {
    const accessInfoList = accessInfo
    let legitimateAccessCount = 0

    // Check if AccessInfoList exists and that it is an array (Couldn't get typescript to agree without this check)
    if (Array.isArray(accessInfoList)) {
      accessInfoList.forEach(individualAccess => {
        // Dive deeper into individual.status
        individualAccess.individualsAccessInfoList.forEach((individualAccessinfoItem: any) => {
          if(individualAccessinfoItem.status === 'ACCESS_GRANTED') {
            legitimateAccessCount++;
          }
        })
      })
    } else {
      console.log('AccessInfoList does not exist or is not an array');
    }

    if(legitimateAccessCount <= 1) {
      return false
    } else {
      return true
    }
  }

  get changeAccessModalDescription() {
    return this.store.getters.getContentfulContent.findRichText('thirdparty.has-access-to.change-access.modal-content')

  }

  stripHtml(html: string): string {
    const doc = new DOMParser().parseFromString(html, 'text/html')
    return doc.body.textContent || ''
  }

  initChangeAccess() {
    this.showChangeAccessModal = true
  }

  cancelChangeAccess() {
    this.showChangeAccessModal = false
  }

  changeAccess() {
    const accessRadios = document.querySelectorAll<HTMLInputElement>('input[name="access-group"]')
    accessRadios.forEach(radio => {
      const access = this.accessInfos.find(x => x.id! === Number(radio.id.replace('radiogroup-access-', '')))
      if(radio.checked && access) {
        this.useAccess(access)
      }
    })

    this.showChangeAccessModal = false

  }

  async checkNotificationMessages() {
    if (isAuthenticated() && !this.isTestFramework) {
      await this.getNotificationsFromDb().then((notes) => {
        notes.forEach(async (note: Notification) => {
          const rid = this.store.getters.getCurrentUser.profile['sub']
          this.eventHub.emit('add-toast', new ToastMessage(
              'NotificationInfo;'+note.id+';'+rid,
              note.type || 'warning',
              true,
              '',
              this.getContentfulStringRT(note.contentfulId === undefined ? '' : note.contentfulId),
              true,
              false,
              true,
              true,
              undefined,
              note.parameterMap
          ))
          if (note.id) {
            const rid = this.store.getters.getCurrentUser.profile['sub']
            //await notificationClient.deleteNotification(note.id, rid)
          }
        })
      })
    }
  }

  async getAccessFromDb() {
    let result: AccessInfo[] = []
    const vatNo = this.getContactPerson.vatNo
    if (vatNo) {
      await accessClient.getAccess(vatNo).then((response) => {
        result = response.data as AccessInfo[]
      })
    }

    return result
  }

  async getNotificationsFromDb() {
    let result: Notification[] = []
    try {
      const vatNo = this.getContactPerson.vatNo
      const rid = this.store.getters.getCurrentUser.profile['sub']
      if (vatNo) {
        await notificationClient.getNotifications(vatNo, rid).then((response) => {
          result = response.data as Notification[]
        })
      }
    } catch (e) {
      console.error("Exception in get notification", e)
    }
    return result
  }

  get currentDate() {
    return new Date().toISOString().substr(0, 10)
  }

  async handlePendingForecasts() {
    this.pendingForecasts.filter((forecast: PendingForecastEntry) => !forecast.pollingCompleted).forEach((forecast: PendingForecastEntry) => {
      if(!this.forecastPollingTimeout){
        this.forecastPollingTimeout = 30000
      }
      //console.log("check pending forecast: " + forecast.forecastName, forecast.createdTime)
      const createdTimeDate = new Date(forecast.createdTime)
      //console.log('this is createdTImeDate = ' + createdTimeDate)
      //console.log('currentForecastPollingTimeout before comparisons = ' + this.forecastPollingTimeout)
      if ((createdTimeDate.getTime() + this.forecastPollingTimeout) < Date.now()) {
        //console.log('createdTimeDate + this.foreCastPollingTimeout = ' + (createdTimeDate.getTime() + this.forecastPollingTimeout) + ' && Date.now= ' + Date.now())
        this.pollingPeriodTimedOut({ ...forecast })
      } else if (forecast.errorArray.length === 0 && !forecast.pollingStarted) {
        //console.log(('errorArray.length === 0 && !forecast.pollingStarted'))
        this.addToastMessage(new ToastMessage('CreateForecastPending' + forecast.forecastId, 'warning', true,
          this.getContentfulString('forecast.calculate-forecast.toast.save-pending.header'), this.getContentfulString('forecast.calculate-forecast.toast.save-pending.message'),
          true, false, true, false, [{ key: '#FORECAST_NAME#', value: forecast.forecastName }]))
        this.pollForecast({ ...forecast })
      } else if (forecast.errorArray.length > 0 && forecast.pollingStarted) {
        //console.log('errorArray.length > 0 && forecast.pollingStarted')
        this.addToastMessage(new ToastMessage('CreateForecastPendingError' + forecast.forecastId, 'error', true,
          this.getContentfulString('forecast.calculate-forecast.toast.save-error.header'), this.getContentfulString('forecast.calculate-forecast.toast.save-pending.message'),
          true, false, true, false, [{ key: '#FORECAST_NAME#', value: forecast.forecastName }]))
        this.pollingFailed({ ...forecast })
      }
    })
  }

  async pollingFailed(forecast: PendingForecastEntry) {
    console.warn('>> Polling failed: ' + forecast.errorArray[0].message ?? 'Ingen besked')
    forecast.pollingCompleted = true
    this.addToastMessage(new ToastMessage('CreateForecastError', 'error',
      true, this.getContentfulString('forecast.calculate-forecast.toast.save-error.header'), this.getContentfulString('forecast.calculate-forecast.toast.save-error.message'),
      true, false, true, false, [{ key: '#FORECAST_NAME#', value: forecast.forecastName }]))
    // Set pending forecast to Completed
    await this.store.dispatch('updatePendingForecast', forecast)
  }

  async pollingPeriodTimedOut(forecast: PendingForecastEntry) {
    console.warn('>> Polling Period timed out: ' + forecast.forecastName)
    //forecast.pollingCompleted = true
    this.terminatePollingForecast(forecast)
    this.addToastMessage(new ToastMessage('CreateForecastTimeout' + forecast.forecastId, 'error',
      true, this.getContentfulString('forecast.calculate-forecast.toast.save-timeout.header'),
      this.getContentfulString('forecast.calculate-forecast.toast.save-timeout.message'),
      true, false, true, false, [{ key: '#FORECAST_NAME#', value: forecast.forecastName }]))
    // Set pending forecast to Completed
    await this.store.dispatch('updatePendingForecast', forecast)
  }

  async pollForecast(forecast: PendingForecastEntry) {
    //console.log(">> Poll forecast: " + forecast.forecastName)
    let timeout = 2000
    if (forecast.currentTimeout > timeout) timeout = forecast.currentTimeout
    //console.log('thio is forecast.currentTimeout: ' + forecast.currentTimeout)
    forecast.pollingStarted = true
    forecast.currentInterval = setInterval(async () => {
      await forecastClient.getForecast(forecast.forecastId,
        this.thirdpartyInfo && this.thirdpartyInfo.behalfOfVatNr ? this.thirdpartyInfo.behalfOfVatNr : 'null').then(async (result: AxiosResponse<ForecastResults>) => {
        forecast.currentTimeout = timeout
        forecast.currentRetryCount++
        if (result.data) {
          if (result.data.forcastHeader?.message && result.data.forcastHeader?.messageStatus) {
            forecast.errorArray.push({
              code: result.data.forcastHeader?.messageStatus,
              message: result.data.forcastHeader?.message
            })
            //console.log(forecast.errorArray.length + '<--this is forecast errorArray.length')
            clearInterval(forecast.currentInterval)
          }
          if ((result.data.calculations?.length ?? 0) > forecast.currentDataSize) {
            /* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
            forecast.currentDataSize = result.data.calculations?.length!
          }
          if (result.data.forcastHeader?.isCreationCompleted === true) {
            this.endPollingForecast(forecast)
          } else if (forecast.currentRetryCount >= 10) {
            forecast.currentTimeout = 10000
            forecast.currentRetryCount = 0
            clearInterval(forecast.currentInterval)
            await this.pollForecast(forecast)
          }
          await this.store.dispatch('updatePendingForecast', forecast)
        }
      })
    }, timeout)
  }

  terminatePollingForecast(forecast: PendingForecastEntry) {
    //console.log('terminatePollingForecast: ' + forecast.forecastName + '<--terminatePollingForecast()' )
    clearInterval(forecast.currentInterval)
    forecast.pollingCompleted = true
    this.store.dispatch('loadForecasts')
    this.eventHub.emit('pending-forecast-completed')
  }

  endPollingForecast(forecast: PendingForecastEntry) {
    //console.log('endPollingForecast: ' + forecast.forecastName + '<---endPollingForecast()')
    clearInterval(forecast.currentInterval)
    forecast.pollingCompleted = true
    const forecastNameSecure = this.stripHtml(forecast.forecastName)
    this.addToastMessage(new ToastMessage(
      'CreateForecastPendingCompleted' + forecast.forecastId,
      'success',
      true,
      this.getContentfulString('forecast.calculate-forecast.toast.save-ok.header'),
      this.getContentfulString('forecast.calculate-forecast.toast.save-ok.message'),
      true,
      false,
      true,
      true,
      [{ key: '#FORECAST_NAME#', value: forecastNameSecure },
        {
          key: '#FORECAST_LINK#',
          value: `<a href='/klimakompasset/forecast/calculate-forecast?forecastId=${forecast.forecastId}' >${forecastNameSecure}<a/>`
        }]))
    this.store.dispatch('loadForecasts')
    this.eventHub.emit('pending-forecast-completed')
  }

  getContentfulString(contentfulKey: string) {
    return this.store.getters.getContentfulContent.findSimpleText(contentfulKey)
  }

  getContentfulStringRT(contentfulKey: string) {
    return documentToHtmlString(this.store.getters.getContentfulContent.findRichText(contentfulKey))
  }

  addToastMessage(toast: ToastMessage) {
    this.eventHub.emit('add-toast', toast)
  }

  addToast(id: string, type: string, header: string, message: string, showCloseButton = true, removeOnTimeout = true) {
    const toast = new ToastMessage(id, type, true, header, message, showCloseButton, removeOnTimeout)
    this.addToastMessage(toast)
  }

  async beforeMount() {
    // Check if authentication should be triggered after reload
    if (localStorage.getItem('oidc_auth_after_reload') === 'true') {
      // Remove the flag to avoid re-triggering on subsequent reloads
      localStorage.removeItem('oidc_auth_after_reload');
      await this.login()
    }
    this.menu = this.store.getters.getMenuStructure.mainMenu
    this.newsProperties = await getPage<NewsHeader>('47pvRfLbOqFO652ePnFTxn')
    await this.store.dispatch('loadContactPerson')
    await this.showIndividualMessages()
    this.store.subscribe((mutation, state) => {
      if (mutation.type === 'accessTokenExpiring') {
        this.toggleNotification(state.accessTokenExpiring)
      }
      if (mutation.type === 'setPendingForecasts') {
        this.handlePendingForecasts()
      }
    })
    await configurationClient
      .getKey('FREMSKRIVNING_POLLING_TIMEOUT', true)
      .then((response) => {
        this.forecastPollingTimeout = response.data as unknown as number
      })
      .catch((error) => {
        console.error(error)
      })
    await configurationClient
        .getKey('NOTIFICATION_POLLING_INTERVAL_MS', true)
        .then((response) => {
          this.notificationPollingInterval = response.data as unknown as number
        })
        .catch((error) => {
          console.error(error)
        })
    if (this.isTestFramework) {
      this.showSSILogin = true
    } else {
      await configurationClient
        .showSSILogin()
        .then((response) => {
          this.showSSILogin = response.data as unknown as boolean
        })
        .catch((error) => {
          console.error(error)
        })
    }

   // Moved back to beforemount() else no papskilt in frontend
    await configurationClient
        .getConfigurationAccess()
        .then((response) => {
          this.hasConfigAccess = (response.data as unknown) as boolean
        })
        .catch((error) => {
          console.error('getConfigurationAccess', error)
        })

  //  await this.store.dispatch("loadGivenAccesses").then(()=>{
  //    this.accessInfos = this.store.getters.getGivenAccesses
  //  }).catch(()=>{
  //    console.error('could not get given access')
  //  })

    // Use store subscription to ensure contactPerson is set in sessionStorage before loading givenAccesses
    this.store.subscribe((mutation, state) => {
      if (mutation.type === "setContactPerson") {
        this.accessInfos = this.store.getters.getGivenAccesses
      }
    })
    setInterval(() => {
      this.checkNotificationMessages()
    }, this.notificationPollingInterval)
    setInterval(() => {
      this.showIndividualMessages()
    }, this.notificationPollingInterval)
    this.eventHub.on("loggedIn", async (arg: string) => {
      this.showIndividualMessages()
    })
  }

  async updated() {
    //this.store.dispatch('clearLoginMethod')
   await this.checkNotificationMessages()
    // moved here from beforeMound because else no userInfo in BE and returned false
    await configurationClient
        .getConfigurationAccess()
        .then((response) => {
          this.hasConfigAccess = (response.data as unknown) as boolean
        })
        .catch((error) => {
          console.error('getConfigurationAccess', error)
        })
  }

  get givenAccessInfos() {
    return this.accessInfos
  }

  async useAccess(access: AccessInfo) {
    console.log("useAccess", access)
    const thirdPartyInfo = {} as ThirdpartyInfo
    thirdPartyInfo.behalfOfVatNr = access.vatId
    thirdPartyInfo.behalfOfCompanyName = access.companyNameSource + ''
    thirdPartyInfo.behalfOfIsPrivate = !!access.privateSource
    thirdPartyInfo.loggedInVatNr = this.store.getters.getCurrentUser.profile['dk:gov:cvr'] || this.store.getters.getCurrentUser.profile['dk:gov:cpr']
    thirdPartyInfo.loggedInCompanyName = this.store.getters.getCurrentUser.profile['name']
    if (access.calculationInfoList && access.calculationInfoList.length > 0 && access.calculationInfoList[0].contactpersonRid) {
      thirdPartyInfo.behalfOfContactpersonRid = access.calculationInfoList[0].contactpersonRid
    }
    thirdPartyInfo.loggedInContactpersonRid = this.store.getters.getCurrentUser.profile['sub']

    await this.store.dispatch("setThirdPartyInfo", thirdPartyInfo)
    //this.showToastAndDelayHide(access)
    //Emits toast arguments, to be caught by header, post page transition to /overview

    let message = JSON.stringify(this.onBehalfOfToastMessage)
    if (thirdPartyInfo.behalfOfIsPrivate) {
      const indexOfCompanyNameEnd = message.indexOf("#companyName#") + '#companyName#'.length
      const indexOfVatNoEnd = message.indexOf("#vatNo#") + '#vatNo#'.length
      if(indexOfCompanyNameEnd > 0 && indexOfVatNoEnd > 0) {
        message = message.replaceAll(message.slice(indexOfCompanyNameEnd, indexOfVatNoEnd), '')
      }
    } else {
      message = message.replaceAll('#vatNo#', thirdPartyInfo.behalfOfVatNr)
      message = message.replaceAll('#companyName#', thirdPartyInfo.behalfOfCompanyName)
    }
    if(thirdPartyInfo.behalfOfIsPrivate) {
      message = message.replaceAll('#companyName#', this.privateUserText)
    } else {
      message = message.replaceAll('#companyName#', thirdPartyInfo.behalfOfCompanyName)
    }

    message = message.replaceAll('"', '')

    this.addToast('AccessToast',
      'info',
      this.onBehalfOfToastHeader,
      message,
      true,
      true
    )
    this.eventHub.emit("start-loading", "CalculationsOverview")
    await this.store.dispatch("loadCalculations").finally(() => {
      this.eventHub.emit("stop-loading", "CalculationsOverview")
    })
    await this.router.push("/overview")
  }
  get onBehalfOfToastMessage() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.onBehalfOfToastMessage')
  }

  async showIndividualMessages() {
    try {
      if (isAuthenticated() && !this.isTestFramework) {
        await this.checkNotificationMessages()

        const MAX_SHOWN_TOASTS = 4 //Including the "last warning"
        let i = 0
        let shownLastWarning = false
        const newsShown = await newsClient.hasBeenShownAll()
        let newsAlreadyShownContentfulIds: any[] = []
        if (newsShown.data) {
          newsAlreadyShownContentfulIds = newsShown.data
        }
        if (this.newsProperties && this.newsProperties.news) {
          for (const column of this.newsProperties.news) {
            if (!newsAlreadyShownContentfulIds.find((news) => news.contentfulId == column.sys.id)) {
              i++
              if (!shownLastWarning) {
                if (i >= MAX_SHOWN_TOASTS) {
                  this.$refs.newsToast.addToast(new ToastMessage(column.sys.id + '', 'info', true,
                      this.toastHeaderMaxNews, documentToHtmlString(this.toastMessageMaxNews), true, false, true, true))
                  shownLastWarning = true
                } else if (column && column.fields && column.fields.type) {
                  switch (column.fields.type) {
                    case 'informationsbesked':
                      this.$refs.newsToast.addToast(new ToastMessage(column.sys.id + '', 'info', true,
                          column.fields.title, documentToHtmlString(column.fields.text), true, false, true, true))
                      break
                    case 'advarselsbesked':
                      this.$refs.newsToast.addToast(new ToastMessage(column.sys.id + '', 'warning', true,
                          column.fields.title, documentToHtmlString(column.fields.text), true, false, true, true))
                      break
                    case 'succesbesked':
                      this.$refs.newsToast.addToast(new ToastMessage(column.sys.id, 'success', true,
                          column.fields.title, documentToHtmlString(column.fields.text), true, false, true, true))
                      break
                    default:
                      break
                  }

                }
              }
            }
          }
        }
      }
    } catch (e) {
      console.error("show individual message error", e)
    }
  }

  get isTestFramework() {
    return getCookie('CYPRESS-TEST').includes('true')
  }

  showNewsOnPage() {
    const urlsToExclude = [
      'accept-terms',
      'contact-information',
      'redirect'
    ]
    const pageUrl = window.location.href
    return !urlsToExclude.some((url: string) => pageUrl.includes(url))
  }

  get endActionText() {
    let endActionText = JSON.stringify(this.endActionModalDialogText)
    if (this.thirdpartyInfo.behalfOfIsPrivate) {
      endActionText = endActionText.replaceAll('#vatNo#', this.cvrIsPrivateText)
      endActionText = endActionText.replaceAll('#companyName#', this.privateUserText)
    } else {
      endActionText = endActionText.replaceAll('#vatNo#', this.thirdpartyInfo.behalfOfVatNr)
      endActionText = endActionText.replaceAll('#companyName#', this.thirdpartyInfo.behalfOfCompanyName)
    }
    return JSON.parse(endActionText)
  }

  get behalfOfText() {
    let behalfOfText = this.onBehalfOfText
    if (this.thirdpartyInfo.behalfOfIsPrivate) {
      behalfOfText = this.onBehalfOfToastHeader + ' ' + this.privateUserText
    } else {
      behalfOfText = behalfOfText.replace('#vatNo#', this.thirdpartyInfo.behalfOfVatNr)
      behalfOfText = behalfOfText.replace('#companyName#', this.thirdpartyInfo.behalfOfCompanyName)
      behalfOfText = behalfOfText.replace('#BREAK#', '<br />')
    }
    return behalfOfText
  }

  get menuLive() {
    const menuLive = Cloneable.deepCopy<Menu>(this.menu)
    if (!this.hasConfigAccess) {
      menuLive.menuItems = menuLive?.menuItems?.filter(obj => obj.id !== 'admin')
    }
    if (this.thirdpartyInfo.behalfOfVatNr) {
      // remove 'indstillinger' from menuitem
      menuLive.menuItems = menuLive.menuItems.filter(obj => obj.id !== 'setup')
      return menuLive
    } else {
      return menuLive
    }
  }

  get expiration() {
    return this.store.getters['oidcStore/oidcAccessTokenExp']
  }

  get isAuthenticated() {
    return this.store.getters.isAuthenticated
  }

  get thirdpartyInfo() {
    return this.store.getters.getThirdpartyInfo
  }

  get isPrivateUser() {
    return this.store.getters.isPrivateUser
  }

  get getCurrentUser() {
    return this.store.getters.getCurrentUser
  }

  get getContactPerson() {
    return this.store.getters.getContactPerson
  }

  get pendingForecasts() {
    return this.store.getters.getPendingForecasts
  }

  get showNavigation() {
    //Dont show if user is not logged in
    if (!this.isAuthenticated) {
      return false
    }

    //Dont show if user is on the terms and conditions page
    if (
      router.currentRoute.value.path.includes('accept-terms') ||
        (router.currentRoute.value.path.includes('/terms-for-use') && !router.currentRoute.value.path.includes('guides/terms-for-use')) ||
      router.currentRoute.value.path.includes('/private-terms-for-use ')
    ) {
      return false
    }

    //Dont show if user is on the contact information page
    if (router.currentRoute.value.path.includes('contact-information') && !this.getContactPerson.firstName) {
      return false
    }

    return true
  }

  async login() {
    await this.store.dispatch('setReturnUrlSessionRefresh', '')
    await this.store.dispatch('oidcStore/authenticateOidc')
  }

  async loginNormal() {
    await this.store.dispatch('setLoginMethod', 'NORMAL')
    // Set a flag in localStorage before reload
    localStorage.setItem('oidc_auth_after_reload', 'true');
    window.location.reload();
  }

  async loginSSI() {
    await this.store.dispatch('setLoginMethod', 'SSI')
    // Set a flag in localStorage before reload
    localStorage.setItem('oidc_auth_after_reload', 'true');
    window.location.reload();
  }

  // Helper function to add a delay
  delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async loginPopup() {
    this.store.dispatch('clearLoginMethod')
    this.loginPressed = true
  }

  async closeLoginDialog() {
    this.store.dispatch('clearLoginMethod')
    this.loginPressed = false
  }

  logout() {
    this.store.dispatch('clearLoginMethod')
    this.store.dispatch('oidcStore/signOutOidc')
    this.store.dispatch('logout')
    this.endAction()
  }

  refreshLogin() {
    this.store.dispatch('setReturnUrlSessionRefresh', router.currentRoute.value.fullPath)
    this.store.dispatch('oidcStore/authenticateOidc')
  }

  confirmEndAction() {
    this.showModalDialog = true
  }

  async endAction() {
    // go back to showing data for my own logged in user by clearing session
    await this.store.dispatch('clearThirdPartyInfo')
    await this.router.push('/')
    this.showModalDialog = false
  }

  async cancelEndAction() {
    this.showModalDialog = false
  }

  async clearMessages() {
    try {
      // clear message in backend
      await this.store.dispatch('setContactPerson', {
        ...this.getContactPerson,
        messages: null
      })
      await contactPersonClient.updateContactPerson(this.getContactPerson)
    } catch (error) {
      console.error('Fejl: ' + error)
    }
  }

  get documentToHtmlString() {
    return documentToHtmlString
  }

  newCalculation() {
    this.store.dispatch('clearCurrentCalculation')
  }

  @Watch('expiration')
  watchExpiration() {
    this.toggleNotification(this.store.getters.accessTokenExpiring)
  }

  private toggleNotification(show: boolean) {
    this.showExpirationNotification = show
    if (show) {
      this.sessionExpirationTime = dayjs(this.expiration, 'ms').format('DD. MMMM YYYY HH:mm:ss')
    } else {
      this.sessionExpirationTime = ''
    }
  }

  setShown(contentfulId: string) {
    const contentfulIds = [contentfulId]
    newsClient.setShown(contentfulIds)
  }

  get onBehalfOfText() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.onBehalfOfText')
  }

  get onBehalfOfToastHeader() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.onBehalfOfToastHeader')
  }

  get endActionModalDialogText() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.endActionModalDialogText')
  }

  get popupMessageTitle() {
    return this.store.getters.getContentfulContent.findSimpleText('sharedContent.popupMessageTitle')
  }

  get popupMessageText() {
    return this.store.getters.getContentfulContent.findSimpleText('sharedContent.popupMessageText')
  }

  get sessionExpirationTitle() {
    return this.store.getters.getContentfulContent.findSimpleText('sharedContent.sessionExpirationTitle')
  }

  get sessionExpirationText() {
    return this.store.getters.getContentfulContent.findSimpleText('sharedContent.sessionExpirationText')
  }

  get toastHeaderMaxNews() {
    return this.store.getters.getContentfulContent.findSimpleText('news.toastHeaderMaxNews')
  }

  get toastMessageMaxNews() {
    return this.store.getters.getContentfulContent.findRichText('news.toastMessageMaxNews')
  }

  get privateUserText() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.private-user')
  }

  get cvrIsPrivateText() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.cvr-private-text')
  }

}
</script>

<style scoped lang='scss'>
.nav-primary .nav-link span {
  border-bottom: 6px solid transparent;
}

.nav-primary .nav-link:hover span,
.nav-primary .current span {
  border-bottom: 6px solid white;
}

.nav-primary .nav-link {
  padding: 18px 12px 0px;
}

.header .portal-header {
  border-bottom: 1px solid white;
}

.header .logo {
  background-image: none;
  width: auto;
  text-decoration: none;
}

.header .logo .logo__name {
  color: white !important;
}

.on-behalf-of {
  background-color: #e2f2fb;
  padding: 10px 12px 10px;
  border-left: 10px solid $color-info-primary;
  width: 100%;
  overflow-wrap: anywhere;
  display: flex;
}

.on-behalf-of .on-behalf-of-body {
  padding-left: 5px;
}

.logo__name {
  word-break: keep-all !important;
}

fieldset {
  width: 100%;
}

.change-access-item {
  align-items: center;
  background: $background-alternative;
  padding: 20px 10px;
  margin-top: 20px;
}

.link-button {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
}

.link-button:hover {
  color: darkblue;
}

.svg-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  color: black;
  border-radius: 5px;
}

.svg-button .icon {
  width: 240px;
  height: 80px;
  margin-right: 8px;
}

</style>
}