import {prettify} from '@/shared/PrettifyNumbers'
import {Vue} from 'vue-class-component'
import {DecimalConfiguration} from '@/shared/DecimalConfiguration'
import {getPage, getPageFull} from '@/shared/ContentService'
import {useStore} from 'vuex'
import {useRoute} from 'vue-router'
import {formatDate, formatDates, formatDatesPlain} from '@/shared/DateFormatHelper'
import {documentToHtmlString} from '@contentful/rich-text-html-renderer'
import {Constants} from '@/shared/Constants'
import {FormValidation} from '@/shared/FormValidation'
import {countryClient} from '@/shared/BackendService'
import {Address, Calculation, Country, Versions} from '../../../../openapi/generated-clients/climatecompass'
import ToastMessage from '@/types/ToastMessage'
import {getCookie} from '@/shared/CookieHelper'
import ContentfulContent from '@/shared/ContentfulContent'
import TitleDescription from '@/types/contentful-types/TitleDescription'
import {getLocale} from "@/store/getters";
import {CombinedField} from "@/types/CombinedField";

/**
 * BaseComponent is the root component of all calculator-components.
 */

export default class BaseComponent extends Vue {
  eventHub = require("tiny-emitter/instance")
  store = useStore()
  route = useRoute()

  currentEF = ''
  currentRFI = ''

  get useTextKeys() {
    return window.sessionStorage.getItem('useTextKeys')?.includes('true') ?? false
  }

  getAccordions(accordionGroup: string | null) {
    // loop arcodions in contentful, note that we can have icons/bitmaps also

    const accordionsList : TitleDescription[] = []
    if(accordionGroup === null) return accordionsList
    let t1 = '<not empty>'
    let i = 0
    while (t1 != null) {
      i++
      t1 = this.store.getters.getContentfulContent.findSimpleTextOrNull(accordionGroup+ '.accordion.title-' + i)
      const d1 = this.store.getters.getContentfulContent.findRichText(accordionGroup +'.accordion.description-' + i)
      const u1 = this.store.getters.getContentfulContent.findSimpleTextOrNull(accordionGroup +'.accordion.icon-' + i)
      if (t1 != null) {
        const keyValue = { title: t1, description: d1, url: u1 }
        accordionsList.push(keyValue)
      }
    }

    return accordionsList
  }

  get Contentful() : ContentfulContent {
    return this.store.getters.getContentfulContent
  }

  findRegexPattern(regexKey: string): string {
    if (!regexKey) {
      return ""
    }
    const regexPattern = this.store.getters.getRegexPatterns.get(regexKey + '-' + getLocale());
    if (!regexPattern) {
      console.error("Regex pattern for key [" + regexKey + "] not found")
    }
    return regexPattern;
  }

  validateLocalizedNumberInput(e: any, type: string) {
    const functionKeyCodeArray = [46, 8, 9, 27, 13, 110, 188, 190]
    const controlCommandArray = [65, 67, 88, 17, 86]

    if(type === 'DECIMAL' || type === 'INPUT' || type === 'SCOPE1_OWN' || type === 'SCOPE2_OWN' || type === 'SCOPE3_OWN' || type === 'OUTSIDE_SCOPE_OWN') {
      if (
          // backspace, delete, tab, escape, enter, comma and .
          functionKeyCodeArray.includes(e.keyCode) ||
          // Ctrl/cmd+A, Ctrl/cmd+C, Ctrl/cmd+X
          (controlCommandArray.includes(e.keyCode) && (e.ctrlKey === true || e.metaKey === true)) ||
          this.isNavKey(e.keyCode) ||
          this.isNumber(e.keyCode) ||
          this.isDashMinus(e) && !(type === 'SCOPE1_OWN' || type === 'SCOPE2_OWN' || type === 'SCOPE3_OWN')
      ) {
        //KEY IS OKAY
        return;
      } else {
        //KEY IS NOT OKAY
        e.preventDefault()
      }
    }
  }

  isNumber(keyCode: any): boolean {
    return (
      // numpad numbers
      (keyCode >= 96 && keyCode <= 105) ||
      // numbers (0-9)
      (keyCode >= 48 && keyCode <= 57)
    )
  }

  isNavKey(keyCode: any): boolean {
    return (
      // home, end, left, right
      (keyCode >= 35 && keyCode <= 39)
    )
  }

  isDashMinus(e: any): boolean {
    return (
      //Specificly dash && minus
      // Minus is keyCode 173 in FireFox and 189 in Chrome, Edge, Safari, Opera and IE. Use key in place of keyCode.
      e.keyCode === 109 || e.key === '-'
    )
  }

  setDocumentTitleByKey(subsectionKey = "") {
    if (subsectionKey) {
      document.title = `${this.getContentfulString(subsectionKey)} | ` + this.getContentfulString("title.klimakompasset")
    } else {
      document.title = this.getContentfulString("title.klimakompasset")
    }
  }

  setDocumentTitle(subsectionText = "") {
    if (subsectionText) {
      document.title = `${subsectionText} | ` + this.getContentfulString("title.klimakompasset")
    } else {
      document.title = this.getContentfulString("title.klimakompasset")
    }
  }

  setRFI(val: boolean) {
    this.currentRFI = this.getRFI(val)
    this.eventHub.emit("rfi-updated", this.currentRFI)
  }

  setEF(val: boolean) {
    this.currentEF = this.getEmmisionFactor(val)
    this.eventHub.emit("ef-updated", this.currentEF)
  }

  getEmmisionFactor(isLocationBased: boolean) {
    if (isLocationBased) {
      return "Miljødeklaration" // aka location based
    } else {
      return "Eldeklaration" // aka marked based
    }
  }

  getRFI(isInclude: boolean) {
    if (isInclude) {
      return "includeRfi"
    } else {
      return "excludeRfi"
    }
  }

  /***
   Not needed when all "legacy" denominations written in plain-text danish, have been replaced with contentfulkeys instead
   */
  parseFieldNameFromDb(input: string, contentfulId: string) : string {
    if (contentfulId) {
      return this.getContentfulString(contentfulId)
    }
    if(input == input.toUpperCase() && input.includes('_') && !input.includes(' ')) {
      return this.getContentfulString(input)
    }
    return input
  }

  async fetchCountryName() {
     return countryClient.fetchCountries().then((response) => {
        return response.data.countries?.find((country: Country) => {
            return country.code === this.country
          })?.name || ""
      }).catch((error) => {
        console.warn(error)
        return ""
      })
}

  get country() {
    return this.calculation.companyInformation?.country
  }

  get formatDates() {
    return formatDates
  }

  get formatDatesPlain() {
    return formatDatesPlain
  }

  get formatDate() {
    return formatDate
  }

  get documentToHtmlString() {
    return documentToHtmlString
  }

  get constants() {
    return Constants
  }

  get calculation() {
    return this.store.getters.getCurrentCalculation
  }

  isDisableBioPart(transportFormField: CombinedField, unitFormField: CombinedField) : boolean {
    console.log('isDisableBioPart, transport:', transportFormField.value, "unit: ", unitFormField.value)
    if (unitFormField.value !== this.constants.FE_ID_L_1
        && unitFormField.value !== this.constants.FE_ID_L_2
        && unitFormField.value !== this.constants.FE_ID_L_3
        && unitFormField.value !== this.constants.FE_ID_L_4
        && unitFormField.value !== this.constants.FE_ID_L_5
        && unitFormField.value !== this.constants.FE_ID_L_6
        && unitFormField.value !== this.constants.FE_ID_L_7
        && unitFormField.value !== this.constants.FE_ID_L_8
        && unitFormField.value !== this.constants.FE_ID_L_9
        && unitFormField.value !== this.constants.FE_ID_L_10
        && unitFormField.value !== this.constants.FE_ID_L_11
        && unitFormField.value !== this.constants.FE_ID_L_12
    ) {
      // disable for everything else than L
      return true
    }
    if (transportFormField.value === this.constants.FE_ID_BUS_DIESEL
        || transportFormField.value === this.constants.FE_ID_LASTBILER_DIESEL
        || transportFormField.value === this.constants.FE_ID_MOTORCYKLER_BENZIN
        || transportFormField.value === this.constants.FE_ID_PERSONBILER_DIESEL
        || transportFormField.value === this.constants.FE_ID_PERSONBILER_BENZIN
        || transportFormField.value === this.constants.FE_ID_PERSONBILER_HYBRID
    ) {
      return false
    }
    if (transportFormField.value !== this.constants.FE_ID_INPUT_ANDEL_BIOGAS) {
      return true
    } else {
      return false
    }
  }

  get isTestFramework() {
    return getCookie('CYPRESS-TEST').includes('true')
  }
  beforeMount() {
    this.currentEF = this.getEmmisionFactor(this.calculation.companyInformation?.country === "DK" ? this.calculation.electricityEnvironmentDeclaration : true)
    this.currentRFI = this.getRFI(this.calculation.rfi)
  }

  doSignifify(val: number) {
    return val > 100 ? val.toFixed(0) : parseFloat(val.toPrecision(2))
  }

  doPrettify(val: any, fractions: number | undefined  = 2) {
    return prettify(val, fractions)
  }

  doForecastPrettify(val: any, fractions: number | undefined = 2) {
    if (val <= 1000000000000) { // 10^12
      return prettify(val, fractions)
    } else {
      return "(> 1 tera ton)"
    }
  }

  getPage<T>(id: string) {
    return getPage<T>(id)
  }

  getPageFull<T>(id: string) {
    return getPageFull<T>(id)
  }

  formatNumber(val: number | string, fractions = DecimalConfiguration.defaultDecimalSetting) {
    return DecimalConfiguration.formatNumber(val, fractions)
  }

  addToastMessage (toast: ToastMessage) {
    this.eventHub.emit("add-toast", toast)
  }

  addToast (id: string, type: string, header: string, message: string, showCloseButton= true, removeOnTimeout = true, onlyOneToastPerId = false, renderAsHtml = false ) {
    const toast = new ToastMessage(id, type, true,
        header, message, showCloseButton, removeOnTimeout, onlyOneToastPerId, renderAsHtml)
    this.addToastMessage(toast)
  }

  addEmissionUpdateToast() {
    this.addToast('emissionUpdateToast', 'info', this.popupMessageTitle, this.popupMessageText, true, false, true)
  }


  addToastFromMessageKeys (id: string, type: string, headerKey: string, messageKey: string, showCloseButton= true, removeOnTimeout = true, stringReplacements: {key: string, value: string}[] | undefined = undefined) {
    const header = this.getContentfulString(headerKey)
    const message = this.getContentfulString(messageKey)

    const toast = new ToastMessage(id, type, true,
        header, message, showCloseButton, removeOnTimeout, false, false, stringReplacements)
    this.addToastMessage(toast)
  }

  scrollToTargetWithOffset(targetId: string, offset= 175) {
    const element = document.getElementById(targetId);
    const headerOffset = offset;
    const elementPosition = element?.getBoundingClientRect().top;
    const offsetPosition = (elementPosition ?? 0) + window.scrollY - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }


  focusInput(elementId: string) {
    const elementToFocus = document.getElementById(elementId)
    if(elementToFocus) {
      elementToFocus.focus()
      elementToFocus.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
    }
  }

  focusFirstError(formValidation: FormValidation) {
    if (formValidation && formValidation.formErrors) {
      const [first] = formValidation.formErrors.keys()
      if (first) this.focusInput(first)
    }
  }

  getStartYear(calculation: Calculation) {
    return Number(calculation.calculationPurpose?.startDate?.substr(0, 4))
  }

  getEndYear(calculation: Calculation) {
    return Number(calculation.calculationPurpose?.endDate?.substr(0, 4))
  }

  getVersionByYear(versions: Versions, year: number): number {
    return versions?.versions?.find(v => v.year == year)?.version ?? -1
  }

  getContentfulString(contentfulKey: string) {
    return this.store.getters.getContentfulContent.findSimpleText(contentfulKey)
  }

  getRenderedContentfulRT(contentfulKey: string, renderAsTemplate = false) {
    return this.store.getters.getContentfulContent.renderRichText(contentfulKey, renderAsTemplate)
  }

  groupingIdToContentfulString(groupingId: number) {
    switch (groupingId) {
      case 1:
        return this.getContentfulString('calculator.grouping.energy')
      case 2:
        return this.getContentfulString('calculator.grouping.purchases')
      case 3:
        return this.getContentfulString('calculator.grouping.transport')
      case 4:
        return this.getContentfulString('calculator.grouping.garbage')
      case 5:
        return this.getContentfulString('calculator.grouping.products')
      default:
        return ""
    }
  }
  // Following functions are necessary in order to display category names in full on the bar chart
  categoryStringFormatter(categoryString: string) {

    const sentence = categoryString.split(' ')
    const results: string[] = []
    let currentSentence: string[] = []
    const threshold = 13

    sentence.forEach((word: string, index: number)=>{
      currentSentence.push(word)
      if(this.stringCharacterCounter(currentSentence) >= threshold || index === (sentence.length -1)) {
        results.push(currentSentence.join(' '))
        currentSentence = []
      }
    })
    return results
  }
  stringCharacterCounter(sArray : string[]) {
    let count = 0
    sArray.forEach((word: string)=>{
      count += word.length
    })
    return count
  }
  resizeCommentField() {
    const commentFieldElem = document.getElementById('input-area')
    if(commentFieldElem) {
      commentFieldElem.removeAttribute('style')
    }
  }

  get popupMessageTitle() {
    return this.store.getters.getContentfulContent.findSimpleText('sharedContent.popupMessageTitle')
  }

  get popupMessageText() {
    return this.store.getters.getContentfulContent.findSimpleText('sharedContent.popupMessageText')
  }

  isCalculationEcVersionAllowedForRecalculation(calculation: Calculation) {
    const emissionCoefficientsVersion = calculation.emissionCoefficientsVersion;
    if (!emissionCoefficientsVersion) {
      return false
    }
    const leastAllowedEcVersion = Constants.LEAST_ALLOWED_EC_VERSION[emissionCoefficientsVersion.year]
    if (!leastAllowedEcVersion) {
      return true //No restriction on version for EC year
    }
    if (emissionCoefficientsVersion.version < leastAllowedEcVersion) {
      return false
    }
    return true
  }

  formatAddressDK(address: Address) {
    return `
        ${address.street}${address.houseNumberFrom ? ' ' + address.houseNumberFrom : ''}${address.houseNumberto ? '-' + address.houseNumberto : ','}
        ${address.floor ? address.floor + ',' : ''}
        ${address.placeName && address.placeName != '' ? address.placeName + ',' : ''}
        ${address.zipCode ? address.zipCode : ''}
        ${address.city ? address.city : ''}${address.state ? ',' + address.state : ''}
      `
  }

  publicCategoryGroup(groupName: string, themeName: string) {
    //console.log("publicCategoryGroup, groupName: [" + groupName + "], themeName: [" + themeName + "]")
    switch (groupName) {
      case Constants.GROUP_NAME_EL:
        return this.getContentfulString('group.text.category.groupelectricity');
      case Constants.GROUP_NAME_VARME_OG_PROCESENERGI:
        return this.getContentfulString('group.text.category.groupheat');
      case Constants.GROUP_NAME_PROCESUDLEDNING:
        return this.getContentfulString('group.text.category.groupprocess');
      case Constants.GROUP_NAME_PRIMARE_INDKOEB_RAAVARER_PRODUKTION:
        return this.getContentfulString('group.text.category.groupprimarypurchasesrawmaterials');
      case Constants.GROUP_NAME_INDKOEB_PRODUKTER_OG_SERVICES:
        return this.getContentfulString('group.text.category.groupproductsservicesprimary');
      case Constants.GROUP_NAME_EGNE_OG_LEASEDE_TRANSPORTFORM:
        return this.getContentfulString('group.text.category.groupownleasedtransports');
      case Constants.GROUP_NAME_MEDARBEJDERTRANSPORT:
        return this.getContentfulString('group.text.category.groupemployeetransport');
      case Constants.GROUP_NAME_VARETRANSPORT_TIL_VIRKSOMHED:
        return this.getContentfulString('group.text.category.grouptransporttocompany');
      case Constants.GROUP_NAME_VARETRANSPORT_FRA_VIRKSOMHED_TIL_KUNDEN:
        return this.getContentfulString('group.text.category.grouptransporttocustomer');
      case Constants.GROUP_NAME_AFFALD:
        return this.getContentfulString('group.text.category.grouptrash');
      case Constants.GROUP_NAME_FORARBEJDNING_AF_SOLGTE_PRODUKTER:
        return this.getContentfulString('group.text.category.groupprocesssoldproducts');
      case Constants.GROUP_NAME_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER:
        return this.getContentfulString('group.text.category.groupusedproducts');
      case Constants.GROUP_NAME_END_OF_LIGE_BEHANDLING:
        return this.getContentfulString('group.text.category.groupendoflife');
      case Constants.GROUP_NAME_SEKUNDARE_INDKOEB_HJAELPEMATERIALER_OG_SERVICEYDELSER:
        return this.getContentfulString('group.text.category.groupsecondaryprocurement');
      case Constants.GROUP_NAME_ANDET:
        switch (themeName) {
          case Constants.THEME_NAME_ENERGY:
            return this.getContentfulString('group.text.category.groupheatprocessother');
          case Constants.THEME_NAME_PURCHASES:
            return this.getContentfulString('group.text.category.groupheatprocessother');
          case Constants.THEME_NAME_TRANSPORT:
            return this.getContentfulString('group.text.category.groupothertransport');
          case Constants.THEME_NAME_TRASH:
            return this.getContentfulString('group.text.category.groupothertrash');
          case Constants.THEME_NAME_SOLDPRODUCTS:
            return this.getContentfulString('group.text.category.groupothersoldproducts');
          default:
            console.error("GROUP_NAME_ANDET theme not found for themeName", themeName);
            return 'FEJL';
        }
      default:
        console.error("GROUP_NAME not found: [" + groupName + "], themeName: [" + themeName + "]");
        return groupName + '!';
    }
  }

  publicCategoryGroupForecastColumnChartPurchase(groupName: string, themeName: string) {
    //console.log("publicCategoryGroupForecastColumnChartPurchase, groupName: [" + groupName + "], themeName: [" + themeName + "]")
    switch (groupName) {
      case Constants.GROUP_NAME_INDKOEB_MATERIALER:
        return this.getContentfulString('group.text.category.grouppurchasesrawmaterials');
      case Constants.GROUP_NAME_INDKOEB_PRODUKTER_OG_SERVICES:
        return this.getContentfulString('group.text.category.groupproductsservices');
      default:
        return this.publicCategoryGroup(groupName, themeName);
    }
  }

}