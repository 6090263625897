<template>
  <div v-if="versionDeprecatedPromptReload" class="spinner-overlay">
    <div aria-hidden="false" class="fds-modal">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">
            <SimpleText text-key="general.application-updated.header"/>
          </h2>
        </div>
        <div class="modal-body">
          <RichText text-key="general.application-updated.message"/>
        </div>

        <div class="modal-footer">
          <button class="button button-primary" @click="refreshBrowser()">
            <simple-text text-key='app.button.reloadandlogout'></simple-text>
          </button>
        </div>
      </div>
    </div>
    <div id="modal-backdrop" class="modal-backdrop"></div>
  </div>
  <div v-if="showCardboard">
    <div class="page-container pt-0 pb-6" role="main">
      <Cardboard :cardboard-type="cardboardSetting"/>
    </div>
  </div>
  <div v-else-if="!isFetching">
    <the-header></the-header>

    <div class="page-container pt-0 pb-6" role="main">
      <div v-if="currentPendingLoads.length > 0 || pendingLoadsTimeout" class="spinner-overlay">
        <div :aria-label="contentfulContent.findSimpleText('loadspinner.general')" class="spinner"/>
        <div class="spinner-status" role="status">
          <SimpleText text-key='loadspinner.general'></SimpleText>
        </div>
      </div>
      <MultipleToast ref="generalMultipleToast" @multipleToastClose='setNotificationShown($event)'/>

      <router-view/>
    </div>
    <button id='cypress_test_context_button' @click.prevent='createTestContext()' style='display:none' v-if='isTestFramework'></button>
    <button id='cypress_test_forecast_button' @click.prevent='createTestForecast()' style='display:none' v-if='isTestFramework'></button>
    <cypress-test-element id='cypress-application-state' :type='cypressTestSignInHost' :inner='cypressTestSignInState'></cypress-test-element>
    <the-footer></the-footer>
  </div>
</template>

<script lang="ts">
import TheHeader from '@/components/layout/TheHeader.vue'
import TheFooter from '@/components/layout/TheFooter.vue'
import {CookiePrompter, PiwikProTracker} from '@/shared/CookiePrompter-2.3.3'
import {getAllEntries} from "./shared/ContentfulService";
import router from '@/router'
import store from './store'
import {mapGetters, useStore} from 'vuex'
import 'dayjs/locale/da'
import dayjs from 'dayjs'
import {Options, Vue} from 'vue-class-component'
import axios, {AxiosError} from "axios"
import {
  getPendingLoads,
  monitorClient,
  testContextClient,
  regexPatternsClient,
  notificationClient
} from '@/shared/BackendService'
import MultipleToast from "@/components/MultipleToast.vue";
import ToastMessage from "@/types/ToastMessage";
import ContentfulContent from "@/shared/ContentfulContent";
import {MenuStructure} from "@/shared/MenuStructure";
import SimpleText from "@/components/SimpleText.vue";
import {getCookie} from "@/shared/CookieHelper";
import RichText from "@/components/RichText.vue";
import Cardboard from "@/components/layout/Cardboard.vue";
import {Constants} from "@/shared/Constants";
import {ModelError} from "../openapi/generated-clients/climatecompass";
import CypressTestElement from '@/components/testhelpers/CypressTestElement.vue'

dayjs.locale('da')

@Options({
  components: {
    CypressTestElement,
    Cardboard,
    RichText,
    TheHeader,
    TheFooter,
    MultipleToast,
    SimpleText
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'getContactPerson', 'hasConsented'])
  },
})
export default class App extends Vue {
  eventHub = require('tiny-emitter/instance')
  cardboardSetting = ''
  isFetching = true
  currentPendingLoads: any = getPendingLoads().getPending()
  generalMultipleToast: any = {}
  pendingLoadsTimeout: any = getPendingLoads().getTimeout()
  store = useStore()
  versionDeprecatedPromptReload = false
  contentfulContent: ContentfulContent = new ContentfulContent(new Map<string, object>(), new Map<string, string>());
  cypressTestSignInState = ''
  cypressTestSignInHost = ''

  get useTextKeys() {
    return window.sessionStorage.getItem('useTextKeys')?.includes('true') ?? false
  }

  async createTestContext() {
    await testContextClient.createAssertionContext({}).then(() => {
      console.log(">> Cypress Test context created")
    })
  }

  async createTestForecast() {
    await testContextClient.createTestForecast({}).then(() => {
      console.log(">> Cypress Test Forecast created")
    })
  }


  refreshBrowser() {
    this.store.dispatch("oidcStore/signOutOidc")
    this.store.dispatch("logout")
    document.location.reload()
    this.versionDeprecatedPromptReload = false
  }

  mounted() {
    //console.log("mounted start")
    this.eventHub.on("add-toast", async (arg: ToastMessage) => {
      if (!this.isFetching) {
        this.generalMultipleToast.addToast(arg)
      } else {
        console.error('Toast mechanism not initialized yet', arg)
      }
    })

    this.eventHub.on('add-error-toast', (modelError: ModelError | undefined, axiosError: AxiosError) => {
      //console.log("Errortoaster event for error", modelError)
      const httpResponseCode = axiosError.response?.status
      const url = axiosError.config?.url
      const method = (axiosError.config?.method ? axiosError.config.method.toLowerCase() : '')
      const errorText = this.contentfulContent.findMatchingError('' + httpResponseCode, modelError?.errorCode, method, url)

      //console.log('Found error key: [' + errorText.key + '] text: [' + errorText.text + '] and replacement texts', modelError?.replacementText)
      if (errorText?.text) {
        let onlyOneToastPerid = true
        if (modelError?.replacementText) {
          onlyOneToastPerid = false
          const replacementMap: Map<string, string> = new Map(Object.entries(modelError.replacementText))
          replacementMap.forEach((value: string, key: string) => {
            errorText.text = errorText.text.replace('#' + key + '#', value ?? '-')
          })
        }
        //console.log('Error text shown:',  errorText)
        if (!this.isFetching) {
          this.generalMultipleToast.addToast(new ToastMessage("serverError-" + errorText.key, "error", true,
              this.contentfulContent.findSimpleText('error.errormap.title'),
              errorText.text,
              true, false, onlyOneToastPerid))
        } else {
          console.error('Toast error mechanism not initialized yet', errorText)
        }
      }
    })

    this.eventHub.on("userclient-deprecated", async () => {
      //console.log("... Got userclient deprication event")
      this.versionDeprecatedPromptReload = true
    })

    this.eventHub.on(Constants.CARDBOARD_UPGRADE, async () => {
      this.cardboardSetting = Constants.CARDBOARD_UPGRADE
    })
    this.eventHub.on(Constants.CARDBOARD_BACKEND_DOWN, async () => {
      this.cardboardSetting = Constants.CARDBOARD_BACKEND_DOWN
    })
    this.eventHub.on(Constants.CARDBOARD_NETWORK_ERROR, async () => {
      this.cardboardSetting = Constants.CARDBOARD_NETWORK_ERROR
    })
    //console.log("mounted end")
  }

  setNotificationShown(notificationInfoId: string) {
    const parts: string[] = notificationInfoId.split(";");
    if (parts.length == 3 && parts[0] === 'NotificationInfo') {
      notificationClient.deleteNotification(Number(parts[1]), parts[2])
    }
  }

  async cacheContentful() {
    // TODO create a solution where multiple call is done
    const contentfulEntries: Map<string, object> = new Map()
    const richTexts: Map<string, object> = new Map()
    const plainTexts: Map<string, string> = new Map()
    await getAllEntries(0).then((result) => {
      result.forEach((entry: any) => {
        contentfulEntries.set(entry.sys.id, entry)
        if (entry.sys.contentType.sys.id === 'richText') {
          richTexts.set(entry.fields.key, entry.fields.value)
        }
        if (entry.sys.contentType.sys.id === 'simpleText') {
          plainTexts.set(entry.fields.key, entry.fields.value)
        }
      })
    })
    await getAllEntries(1000).then((result) => {
      result.forEach((entry: any) => {
        contentfulEntries.set(entry.sys.id, entry)
        if (entry.sys.contentType.sys.id === 'richText') {
          richTexts.set(entry.fields.key, entry.fields.value)
        }
        if (entry.sys.contentType.sys.id === 'simpleText') {
          plainTexts.set(entry.fields.key, entry.fields.value)
        }
      })
    })
    await getAllEntries(2000).then((result2) => {
      result2.forEach((entry: any) => {
        contentfulEntries.set(entry.sys.id, entry)
        if (entry.sys.contentType.sys.id === 'richText') {
          richTexts.set(entry.fields.key, entry.fields.value)
        }
        if (entry.sys.contentType.sys.id === 'simpleText') {
          plainTexts.set(entry.fields.key, entry.fields.value)
        }
      })
      store.dispatch("setContentfulEntries", contentfulEntries)
      this.contentfulContent = new ContentfulContent(richTexts, plainTexts, this.useTextKeys);
      store.dispatch("setContentfulContent", this.contentfulContent)
    })
  }

  async cacheRegexPatterns() {
    const regexPatternsMap: Map<string, string> = new Map()
    await regexPatternsClient.fetchRegexPatterns().then((result) => {
      result.data.regexPatterns?.forEach((entry: any) => {
        regexPatternsMap.set(entry.regexKey + '-' + entry.locale, entry.regexPattern)
        store.dispatch("setRegexPatterns", regexPatternsMap)
      })
    }).catch(error => {
      console.error('cacheRegexPatterns error occurred:', error)
    })
  }


    setupMenus() {
    const menuStructure = new MenuStructure(store.getters.getContentfulContent)
    store.dispatch("setMenuStructure", menuStructure)
  }

  async created() {
    //console.log("created start")
    const timestampNow = Date.now()
    await this.store.dispatch("setUserClientLoadTimestamp", timestampNow)
    // console.log("Setting userclient load timestamp to " + timestampNow)
    try {

      await this.cacheContentful()
      await this.cacheRegexPatterns()
      this.setupMenus()
      let piwikAccountId = ''
      await monitorClient.piwikAccountId().then((resp) => {
        piwikAccountId = resp.data
      }).catch((error) => {
        console.error('Piwik id fetch error', error)
      })
      //console.log("piwik", piwikAccountId)
      this.isFetching = false

      CookiePrompter.init({
        trackers: [
          {
            name: PiwikProTracker,
            config: {
              account: piwikAccountId
            }
          }
        ],
        readMoreUrl: this.readMoreUrl,
        textHeader: this.title,
        textReadMore: this.readMoreText,
        textblock1: this.text,
        textAccept: this.acceptButtonText,
        textDontAccept: this.dontAcceptButtonText
      })

      // The user cannot have access to the authenticated part of the app
      // unless the user has consented and filled and saved the contact information form
      if (store.getters.isAuthenticated) {
        // If the user is authenticated and the user is on one the following routes,
        // we don't do anything
        if (
            router.currentRoute.value.path === '/accept-terms' ||
            router.currentRoute.value.path === '/terms-for-use' ||
            router.currentRoute.value.path === '/private-terms-for-use' ||
            router.currentRoute.value.path === '/cookie-policy'
        ) {
          return
        }


        await store.dispatch('loadConsent')

        // Gets current user consent
        const consent = store.getters.hasConsented

        // If the user has not consented and the user is not on the TOC route,
        // we redirect the user to the consent route
        if (!consent && router.currentRoute.value.path !== '/terms-for-use') {
          return router.push('/accept-terms')
        }

        // Load the ContactPerson data so its available
        await store.dispatch('loadContactPerson')

        // If the user has consented and hasn't filled in the ContactPerson form,
        // we redirect the user to contact information route
        /*
      kk2-305 this makes the 'læs betingelser for brug' not working, no need for this check anyway as we cannot continue if fields are not filled
      if (consent && !store.getters.getContactPerson.firstName) {
        return router.push("/contact-information");
      }
      */
      }
    } catch (e) {
      console.log("Init error", e)
    }
    //console.log("created end")
  }

  get showCardboard() {
    //console.log("route: ", this.$route.name, this.$route)
    if (this.cardboardSetting && this.$route.name != 'Config' && this.$route.name != 'auth') {
      return true
    } else {
      return false
    }
  }

  get isTestFramework() {
    return getCookie('CYPRESS-TEST').includes('true') &&
      (location.host.includes('localhost:8079') ||
        location.host.includes('dev.klimakompasset.dk')||
        location.host.includes('dev2.klimakompasset.dk')||
        location.host.includes('localhost.erstdev.dk') ||
        location.host.includes('ci.klimakompasset.dk') ||
        location.host.includes('ci2.klimakompasset.dk'))
  }

  get isTestApplication() {
    return location.host.includes('ci2.klimakompasset.dk') || location.host.includes('ci.klimakompasset.dk')
  }

  get readMoreUrl() {
    return this.store.getters.getContentfulContent.findSimpleText('cookieprompter.readMoreUrl')
  }

  get title() {
    return this.store.getters.getContentfulContent.findSimpleText('cookieprompter.title')
  }

  get readMoreText() {
    return this.store.getters.getContentfulContent.findSimpleText('cookieprompter.readMoreText')
  }

  get text() {
    return this.store.getters.getContentfulContent.findSimpleText('cookieprompter.text')
  }

  get acceptButtonText() {
    return this.store.getters.getContentfulContent.findSimpleText('cookieprompter.acceptButtonText')
  }

  get dontAcceptButtonText() {
    return this.store.getters.getContentfulContent.findSimpleText('cookieprompter.dontAcceptButtonText')
  }

}
</script>
<style lang="scss">
.spinner-overlay {
  z-index: 500;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: visible;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);

  .spinner {
    filter: invert(1);
    position: relative;
    top: 40%;
  }

  .spinner-status {
    color: white;
    position: relative;
    top: 40%;
  }
}
</style>
