<template>
  <footer v-if='!isTestFramework' class="footer d-print-none ghost-footer" :class="{'slide-down': !showGhostFooter}" id='ghost-footer'>
    <div class="container">
      <div class="row">
         <div class="col-9 pl-0">
        </div>
        <div class="col-3 pr-0">
          <section>
            <div class="align-text-left text-color-white"><SimpleText text-key='footer.supportedby'/></div>
            <div class="eu-logo" />
          </section>
        </div>
      </div>
    </div>
  </footer>
  <footer class="footer d-print-none">
    <div class="container">
      <div class="row py-9">
        <div class="col pl-0">
          <section>
            <div class="align-text-left text-color-white"
                 v-html="documentToHtmlString(this.authority)" />
          </section>
        </div>
        <div class="col">
          <section>
            <div class="align-text-left text-color-white"
                 v-html="documentToHtmlString(this.contact)" />
          </section>
        </div>
        <div class="col">
          <section>
            <div class="align-text-left text-color-white"
                 v-html="documentToHtmlString(this.links)" />
            <p v-if="version" class="align-text-left text-color-white">
              <span>Version: </span>
              <span v-if="versionPrefix">{{ versionPrefix }}</span>
              <span id='version-span'>&nbsp;{{ version }}</span>
            </p>
          </section>
        </div>
        <div class="col pr-0">
          <section>
            <div style='font-weight: bold; color:white;'><SimpleText text-key='footer.supportedby'/></div>
            <div class="eu-logo" />
          </section>
        </div>
      </div>
      <div v-if="languageSelectEnabled" class="row py-6 border-top">
        <div class="language-switcher">
          <div class="container">
            <ul aria-label="Vælg sprog fra listen ">
              <li>
                <a href="?lang=da"
                   lang="da"
                   :class="currentLocale === 'da' && 'selected'"
                   :aria-label="currentLocale === 'da' ? 'Valgt sprog: Dansk' : 'Vælg sprog: Dansk'"
                   @click.prevent="()=>setLocale('da')">
                  <svg xmlns="http://www.w3.org/2000/svg"
                       height="24"
                       viewBox="0 0 24 24"
                       width="24"
                       v-if="currentLocale === 'da'">
                    <path d="M0 0h24v24H0V0z" fill="none"/>
                    <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
                  </svg>
                  Dansk
                </a>
              </li>
              <li>
                <a href="?lang=en-US"
                   lang="en-US"
                   :class="currentLocale === 'en-US' && !useTextKeys && 'selected'"
                   :aria-label="currentLocale === 'en-US' && !useTextKeys ? 'Selected language: English' : 'Select language: English'"
                   @click.prevent="()=>setLocale('en-US')">
                  <svg xmlns="http://www.w3.org/2000/svg"
                       height="24"
                       viewBox="0 0 24 24"
                       width="24"
                       v-if="currentLocale === 'en-US' && !useTextKeys ">
                    <path d="M0 0h24v24H0V0z" fill="none"/>
                    <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
                  </svg>
                  English
                </a>
              </li>
              <li v-if='isDevOrTest'>
                <a href="?lang=en-US"
                   lang="en-US"
                   :class="currentLocale === 'en-US' && useTextKeys && 'selected'"
                   :aria-label="currentLocale === 'en-US' && useTextKeys ? 'Selected language: Tekstnøgler' : 'Select language: Tekstnøgler'"
                   @click.prevent="()=>setLocale('en-US', true)">
                  <svg xmlns="http://www.w3.org/2000/svg"
                       height="24"
                       viewBox="0 0 24 24"
                       width="24"
                       v-if="currentLocale === 'en-US' && useTextKeys ">
                    <path d="M0 0h24v24H0V0z" fill="none"/>
                    <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
                  </svg>
                  Tekstnøgler
                </a>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">

import { MARKS } from "@contentful/rich-text-types"
import { Options, Vue } from 'vue-class-component'
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import { version } from "../../../package.json"
import {useStore} from "vuex";
import {getAllEntries} from "@/shared/ContentfulService";
import {Watch} from "vue-property-decorator";
import {configurationClient} from "@/shared/BackendService";
import { getScrollPosition } from '@/shared/ScrollPosition'
import { getCookie } from '@/shared/CookieHelper'
import SimpleText from '@/components/SimpleText.vue'

@Options({
  components: { SimpleText }
})
export default class TheFooter extends Vue {
  eventHub = require('tiny-emitter/instance')

  get isDevOrTest() {
    const host = document.location.host.split('.')[0]
    return host === "dev" || host === "dev2" || host === "test" || host === "localhost" || host === "localhost:8079"
  }

  get useTextKeys() {
    return window.sessionStorage.getItem('useTextKeys')?.includes('true') ?? false
  }

  get version() {
    return version
  }

  get isTestFramework() {
    return getCookie('CYPRESS-TEST').includes('true')
  }

  showGhostFooter = true
  showGhostAnimationCompleted = false

  store = useStore();
  currentLocale = this.store.getters.getLocale;

  // Set to true to enable user selection of language in the bottom of the screen
  languageSelectEnabled = true;

  contentfulFormatOptions = {
    renderMark: {
      [MARKS.BOLD]: (text: string) => `<strong>${text}</strong>`,
      [MARKS.UNDERLINE]: (text: string) => `<span style="text-decoration:underline">${text}</span>`
    }
  }
  versionPrefix =
    document.location.host.split(".")[0] === "dev2" ||
    document.location.host.split(".")[0] === "dev" ||
    document.location.host.split(".")[0] === "localhost" ||
    document.location.host.split(".")[0] === "test" ||
    document.location.host.split(".")[0] === "preprod"
      ? document.location.host.split(".")[0]
      : false

  get documentToHtmlString() {
    return documentToHtmlString
  }

  async beforeMount() {
    //Moved Ghost-footer functionality from BeforeMount -> Mounted, as ID came up null recently, which prevented footer from activating.
 /*   window.addEventListener('scroll', this.handleScroll)
    const ghostFooter = document.getElementById('ghost-footer')
    if(ghostFooter) {
      const hasVScroll = this.hasVerticalScroll()
      if(hasVScroll) {
        console.log('hasVScroll')
        ghostFooter.classList.add('slide-up')
      } else if(ghostFooter.classList.contains('slide-up')) {
        ghostFooter.classList.remove('slide-up')
      }
      ghostFooter.onanimationend = () => {
        this.showGhostAnimationCompleted = true
      }
    } */
  }

  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    const ghostFooter = document.getElementById('ghost-footer')
    if(ghostFooter) {
      const hasVScroll = this.hasVerticalScroll()
      if(hasVScroll) {
        ghostFooter.classList.add('slide-up')
      } else if(ghostFooter.classList.contains('slide-up')) {
        ghostFooter.classList.remove('slide-up')
      }
      ghostFooter.onanimationend = () => {
        this.showGhostAnimationCompleted = true
      }
    }
  }


  hasVerticalScroll(){

      if(window.innerHeight){
        return document.body.offsetHeight> window.innerHeight;
      }
      else {
        return  document.documentElement.scrollHeight >
          document.documentElement.offsetHeight ||
          document.body.scrollHeight>document.body.offsetHeight;
      }
  }

  handleScroll() {
    const cookiePrompter = document.getElementById('eksCookiePrompt')
    if(!cookiePrompter && this.showGhostAnimationCompleted) {
      this.showGhostFooter = Number(getScrollPosition()) === 0
      window.removeEventListener('scroll', this.handleScroll)
    }
  }

  async cacheContentful() {
    const contentfulEntries: Map<string, object> = new Map();
    await getAllEntries(0).then((result) => {
      result.forEach(entry => {
        contentfulEntries.set(entry.sys.id, entry)
      })
    });
    await getAllEntries(1000).then((result) => {
      result.forEach(entry => {
        contentfulEntries.set(entry.sys.id, entry)
      })
    });
    await getAllEntries(2000).then((result) => {
      result.forEach(entry => {
        contentfulEntries.set(entry.sys.id, entry)
      })
    });
    await this.store.dispatch("setContentfulEntries", contentfulEntries);
  }

  async setLocale(localeString: string, showTextKeys = false) {
    await this.store.dispatch('setUseTextKeys', showTextKeys)
    await this.store.dispatch('setLocale', localeString);
    await this.cacheContentful();
    window.location.reload();
  }

  get authority() {
    return this.store.getters.getContentfulContent.findRichText('footer.authority')
  }

  get contact() {
    return this.store.getters.getContentfulContent.findRichText('footer.contact')
  }

  get links() {
    return this.store.getters.getContentfulContent.findRichText('footer.links')
  }
}
</script>
<style scoped>
.border-top {
  border-top: 1px solid #FFFFFF;
}
.language-switcher {
  border-top: none;
  background-color: transparent;
}
.language-switcher ul > li:first-of-type a,
.language-switcher ul > li:first-of-type a:hover,
.language-switcher ul > li:first-of-type a:visited,
.language-switcher ul > li:first-of-type a:active,
.language-switcher ul > li:first-of-type a:focus,
.language-switcher ul > li a,
.language-switcher ul > li a:visited,
.language-switcher ul > li a svg,
.language-switcher ul > li::after {
  color: #ffffff;
  fill: #ffffff;
}
.language-switcher ul > li a:not(.selected),
.language-switcher ul > li:first-of-type a:not(.selected):hover {
  font-weight: normal;
  text-decoration: underline;
}
.language-switcher ul > li a.selected {
  font-weight: 600;
  text-decoration: none;
}

.language-switcher ul > li > a > svg {
  position: relative;
  top: 5px;
  right: -5px;
}

.eu-logo::after {
  content: '';
  position: absolute;
  right: 0;
  left: 3px;
  top:0;
  display: block;
  width: 300px;
  height: 108px;
  background-image: url('~@/assets/eu-logo.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right center;
}

.ghost-footer {
  position: fixed;
  width: 100%;
  right: 0;
  padding-bottom: 128px;
  padding-top: 48px;
  color:white !important;
  font-weight: bold;
  bottom: -200px;
  opacity: 0;
}

.slide-up {
  animation: slideup 2s forwards;
  animation-delay: 0.5s;
}

.slide-down {
  animation: slidedown 1s forwards;
}

.footer:not(.ghost-footer) {
  position: relative;
  z-index: 1;
}

.ghost-footer {
  z-index: 0;
}

@keyframes slidedown {
  0% {
    bottom: 0;
    opacity: 1;
    /*visibility: visible;*/
  }

  99% {
    opacity: 1;
  }
  100% {
    bottom: -200px;
    opacity: 0;
    /*visibility: hidden;*/
  }

}

@keyframes slideup {
  0% {
    bottom: -200px;
    /*visibility: hidden;*/
    opacity: 0;
  }

  1% {
    opacity: 1;
  }

  100% {
    bottom: 0;
    opacity: 1;
    /*visibility: visible;*/
  }
}
</style>
