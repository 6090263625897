<template>
  <Navigation />
  <div class="container px-0 py-9">
    <div v-if="showSuccess" id="success-box" class="alert alert-success">
      <div class="alert-body">
        <p class="alert-heading"><SimpleText text-key="contactInformation.successTitle"/></p>
        <p class="alert-text">
          <SimpleText text-key="contactInformation.successText"/>
        </p>
      </div>
    </div>

    <div v-if="formValidation.formErrors.size" aria-atomic="true" class="alert alert-error" role="alert">
      <div class="alert-body">
        <p class="alert-heading"><SimpleText text-key="sharedContent.formErrorTitle"/></p>
        <ul class="alert-text">
          <li v-for="[key, value] of formValidation.formErrors" :key="key" :data-cy="'error_' + key">
            {{ value }}
          </li>
        </ul>
      </div>
    </div>

    <h1>
      {{ isPrivateUser ? this.titlePrivateUser : this.titleCorporateUser }}
    </h1>

    <div class="py-1">
      <div><RichText text-key="contactInformation.text"/> </div>
    </div>

    <div class="py-3">
      <form @change="formValidation.clearValidField" @submit.prevent="submitForm"
            @invalid.capture.prevent="formValidation.handleFormValidity">
        <div class="form-group">
          <div v-if="!isPrivateUser" class="py-4 my-0 form-group">
            <label class="form-label" for="input-type-company-email">
              <SimpleText text-key="calculation.contactinfo.text.company-email"/>
            </label>
            <input
              id="input-type-company-email"
              v-model="contactPerson.corporateEmail"
              :data-error="this.emailError"
              class="form-input"
              name="input-type-email"
              :pattern="findRegexPattern('regex.validation.e-mail')"
              maxlength="50"
              minlength="5"
              required
              type="email"
            />
          </div>

          <h3 v-if="!isPrivateUser">
            <SimpleText text-key="calculation.contactinfo.text.contactperson"/>
          </h3>

          <div class="py-4 my-0 form-group">
            <label class="form-label" for="input-type-first-name">
              <SimpleText text-key="calculation.contactinfo.text.first-name"/>
            </label>

            <input
              id="input-type-first-name"
              v-model="contactPerson.firstName"
              :data-error="this.firstNameError"
              class="form-input"
              name="input-type-first-name"
              :pattern="findRegexPattern('regex.validation.person-name')"
              required
              type="text"
            />
          </div>

          <div class="py-4 my-0 form-group">
            <label class="form-label " for="input-type-last-name">
              <SimpleText text-key="calculation.contactinfo.text.last-name"/>
            </label>

            <input
              id="input-type-last-name"
              v-model="contactPerson.lastName"
              :data-error="this.lastNameError"
              class="form-input"
              name="input-type-last-name"
              :pattern="findRegexPattern('regex.validation.person-name')"
              required
              type="text"
            />
          </div>
          <div class="py-4 my-0 form-group">
            <label class="form-label " for="input-type-email">
              <SimpleText text-key="calculation.contactinfo.text.email"/>
            </label>

            <input
              id="input-type-email"
              v-model="contactPerson.email"
              :data-error="this.emailError"
              class="form-input"
              name="input-type-email"
              :pattern="findRegexPattern('regex.validation.e-mail')"
              maxlength="100"
              minlength="5"
              required
              type="email"
            />
          </div>
          <!--div class="py-6">
            <input
              id="checkbox-consent"
              type="checkbox"
              name="checkbox-consent"
              v-model="contactPerson.newsEnabled"
              class="form-checkbox checkbox-large"
            />
            <label for="checkbox-consent"
              ><strong>{{ pageProperties.confirmText }}</strong></label>
          </div-->
          <div v-if="!hideConsentCheck" class="py-6">
            <input
              id="checkbox-terms-consent"
              v-model="termsEnabled"
              class="form-checkbox checkbox-large"
              name="checkbox-terms-consent"
              type="checkbox"
            />
            <label for="checkbox-terms-consent"><strong><SimpleText text-key="acceptterms.acceptTerms"/></strong></label>

            <router-link :to="`/terms-for-use`" target="_blank"><SimpleText text-key="acceptterms.acceptTermsLink"/></router-link>
            <div>
              <SimpleText text-key="acceptterms.acceptTermsText"/>
            </div>
          </div>
          <!-- out for now 605 div class="py-6">
            <input
                id="checkbox-personterms-consent"
                type="checkbox"
                name="checkbox-personterms-consent"
                v-model="personTermsEnabled"
                class="form-checkbox checkbox-large"
            />
            <label for="checkbox-personterms-consent"><strong>{{ acceptTerms.acceptPersonTerms }}</strong></label>
            &nbsp;
            <router-link
                :to="`/private-terms-for-use`"
                target="_blank">{{ acceptTerms.acceptPersonTermsLink }}
            </router-link>

          </div-->
        </div>
        <button id="submit-button" class="button button-primary px-8 my-4 saveButton" type="submit" :disabled="disableSaveButton">
          <SimpleText text-key="sharedContent.save"/>
        </button>
      </form>
    </div>
  </div>

  <template v-if="showTermsModal">
    <AcceptModal
        :title-text="isPrivateUser ? this.withdrawPrivateTitle : this.withdrawCompanyTitle"
        :buttons-disabled="disableTermsModalButtons"
        :button-ok-text-key="'contact-information.terms-modal.button.ok'"
        :button-reject-text-key="'contact-information.terms-modal.button.cancel'"
        @select-ok="yes"
        @select-reject="no"
    >
      <div class="modal-body">
        <div
            class="full-width"
            v-html="documentToHtmlString(isPrivateUser ? this.withdrawPrivateText : this.withdrawCompanyText)"
        ></div>
      </div>
    </AcceptModal>
  </template>

</template>

<script lang="ts">
import { useRouter } from "vue-router"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import { FormValidation } from "@/shared/FormValidation"
import { useStore } from "vuex"
import { ContactPerson } from "openapi/generated-clients/climatecompass"
import { consentClient } from "@/shared/BackendService"
import { Vue, Options } from "vue-class-component"
import { Watch } from "vue-property-decorator"
import Navigation from "@/components/thirdparty/Navigation.vue"
import AcceptModal from "@/components/AcceptModal.vue"
import SimpleText from "@/components/SimpleText.vue"
import {Cloneable} from "@/shared/DeepCopyHelper"
import RichText from "@/components/RichText.vue";
import BaseComponent from "@/components/calculator/base-components/BaseComponent";

@Options({
  components: {
    RichText,
    Navigation,
    AcceptModal,
    SimpleText
  }
})
export default class ContactInformation extends BaseComponent {
  router = useRouter()
  store = useStore()

  showSuccess = false
  termsEnabled = false
  personTermsEnabled = false
  contactPersonGlobal = {}
  formValidation = new FormValidation()
  allowLeave = false

  showTermsModal = false
  disableTermsModalButtons = false
  disableSaveButton = false

  currentContactPerson: ContactPerson = {} as ContactPerson

  get contactPerson() {
    return this.currentContactPerson
  }

  set contactPerson(val: ContactPerson) {
    this.currentContactPerson = val
  }

  get consent() {
    consentClient.getConsent().then((response) => {
      return response.data
    }).catch((error) => {console.error(error)})
    return false
  }

  hideConsentCheck = false

  get isPrivateUser() {
    return this.store.getters.isPrivateUser
  }

  get documentToHtmlString() {
    return documentToHtmlString
  }

  async beforeMount() {
    await this.store.dispatch("loadContactPerson")

    //console.log("getContactPerson:", this.store.getters.getContactPerson)
    if (this.store.getters.getContactPerson.firstName) {
      this.contactPerson = Cloneable.deepCopy<ContactPerson>(this.store.getters.getContactPerson)
    } else {
      if (this.store.getters.isSSIUser) {
        this.contactPerson.email = this.store.getters.getEmail === null ? '' : this.store.getters.getEmail
        if (this.store.getters.getName !== null) {
          const [firstPart, ...rest] = this.store.getters.getName.split(' ');
          const secondPart = rest.join(' ');
          this.contactPerson.firstName = firstPart
          this.contactPerson.lastName = secondPart
        }
      }
    }
    //console.log("getContactPerson:", this.contactPerson)

    if (this.contactPerson.personTermsEnabled != undefined) {
      this.personTermsEnabled = this.contactPerson.personTermsEnabled
    }

    if (this.contactPerson.termsEnabled != undefined) {
      this.termsEnabled = this.contactPerson.termsEnabled
    }

    if (this.contactPerson.firstName) {
      if (!this.store.getters.isSSIUser) {
        this.allowLeave = true
      }
      this.setDocumentTitleByKey("title.contact-info.edit")
    } else {
      this.setDocumentTitleByKey("title.contact-info.create")

      // if we in the onboarding flow (i.e. submitting NOT editing contact info)
      // and if user has consented already (i.e. from AcceptTerms view)
      // we hide the checkbox and set the local consent variable (termsEnabled)
      if (this.consent) {
        this.termsEnabled = true
        this.hideConsentCheck = true
      }
    }
  }

  beforeRouteLeave(to: any, from: any, next: (arg0: boolean | undefined) => void) {
    if (this.allowLeave) {
      next(undefined)
    } else {
      window.alert(this.leaveError)
      next(false)
    }
  }

  async submitForm(e: Event) {
    //console.log("submitForm: ", this.contactPerson, "allow", this.allowLeave)
    this.contactPersonGlobal = this.contactPerson
    //console. log("submit", "personTerms:", personTermsEnabled, "term:", termsEnabled, "private:", isPrivateUser)
    if (!this.termsEnabled) {
        this.showTermsModal = true
        e.preventDefault()
        return
    }
    const element = e.target as HTMLFormElement
    if (element.reportValidity()) {
        this.disableSaveButton = true
        await this.store.dispatch("setContactPerson", this.contactPerson).finally(() => {
          this.disableSaveButton = false
        })
        if (!this.allowLeave) {
          this.allowLeave = true
          await this.router.push("/redirect")
        } else {
          this.formValidation.formErrors.clear()
          this.showSuccess = true
        }
    }
  }

  yes(event: any) {
    // Disable the modal buttons to prevent user interaction, once request is send
    this.disableTermsModalButtons = true;

    // Withdraw consent and redirect to login
    consentClient.withdrawConsent().then(
        () => {
          this.store.dispatch("oidcStore/signOutOidc");
          this.store.dispatch("logout");
          this.showTermsModal = false;
        },
        () => {
          window.alert("Der opstod en fejl");
        }
    ).finally(() => {
      // Re-enable the modal buttons when done
      this.disableTermsModalButtons = false;
    });
  }

  no(event: any) {
    // close dialog and toggle accept on again
    try {
      this.showTermsModal = false
      this.termsEnabled = true
      this.contactPerson.termsEnabled = true
    } catch (error) {
      console.warn(error)
    }
  }

  @Watch("termsEnabled")
  watchTermsEnabled(first: boolean | undefined) {
    if (!first) {
      try {
        this.store.commit('setConsent', false)
        // is parent (terms) is disabled then child (person terms) must also
        //this.contactPerson.personTermsEnabled = false
        this.personTermsEnabled = false
      } catch (error) {
        console.warn(error)
      }
    }
    try {
      this.contactPerson.termsEnabled = first
    } catch (error) {
      console.warn(error)
    }
  }

  @Watch("personTermsEnabled")
  watchPersonTermsEnabled(first: any) {
    try {
      this.contactPerson.personTermsEnabled = first
    } catch (error) {
      console.warn(error)
    }
  }

  get titlePrivateUser() {
    return this.store.getters.getContentfulContent.findSimpleText('contactInformation.titlePrivateUser')
  }

  get titleCorporateUser() {
    return this.store.getters.getContentfulContent.findSimpleText('contactInformation.titleCorporateUser')
  }

  get emailError() {
    return this.store.getters.getContentfulContent.findSimpleText('contactInformation.emailError')
  }

  get firstNameError() {
    return this.store.getters.getContentfulContent.findSimpleText('contactInformation.firstNameError')
  }

  get lastNameError() {
    return this.store.getters.getContentfulContent.findSimpleText('contactInformation.lastNameError')
  }

  get withdrawPrivateTitle() {
    return this.store.getters.getContentfulContent.findSimpleText('contactInformation.withdrawPrivateTitle')
  }

  get withdrawCompanyTitle() {
    return this.store.getters.getContentfulContent.findSimpleText('contactInformation.withdrawCompanyTitle')
  }

  get leaveError() {
    return this.store.getters.getContentfulContent.findSimpleText('contactInformation.leaveError')
  }

  get withdrawPrivateText() {
    return this.store.getters.getContentfulContent.findRichText('contactInformation.withdrawPrivateText')
  }

  get withdrawCompanyText() {
    return this.store.getters.getContentfulContent.findRichText('contactInformation.withdrawCompanyText')
  }

  get text() {
    return this.store.getters.getContentfulContent.findRichText('contactInformation.text')
  }

}
</script>
