import { User } from 'oidc-client'
import {
  AccessInfo,
  Calculation,
  ComparisonOverviewInfo,
  ContactPerson,
  EMFMasterdata,
  ForecastInfo,
  Statistic
} from 'openapi/generated-clients/climatecompass'
import { State } from '.'
import ThirdpartyInfo from "@/types/ThirdpartyInfo";
import ContentfulContent from "@/shared/ContentfulContent";
import {MenuStructure} from "@/shared/MenuStructure";
import Forecast from "@/components/forecast/Forecast.vue";
import {PendingForecastEntry} from "@/types/PendingForecastEntry";

export const logout = (state: State) => {
  state.currentUser = {} as User
  state.contactPerson = {} as ContactPerson
  state.accessTokenExpiring = false
}

export const login = (state: State, user: User) => {
  state.currentUser = user
  state.accessTokenExpiring = false
}

export const accessTokenExpiring = (state: State, value: boolean) => {
  state.accessTokenExpiring = value
}

export const setUserClientLoadTimestamp = (state: State, timstamp: string) => {
  state.userClientLoadTimestamp = timstamp
}

export const setReturnUrlSessionRefresh = (state: State, value: boolean) => {
  // does nothing on purpose
}

export const setLoginMethod = (state: State, value: string) => {
  // does nothing on purpose
  state.setLoginMethod = value
}

export const setSessionLocale = (state: State, value: string) => {
  state.currentLocale = value
}

export const setUseTextKeys = (state: State, value: boolean) => {
  state.useTextKeys = value
}

export const setContactPerson = (state: State, contactPerson: ContactPerson) => {
  state.contactPerson = contactPerson
}

export const setConsent = (state: State, consent: boolean) => {
  state.hasConsented = consent
}

export const setCurrentCalculation = (state: State, calculation: Calculation) => {
  state.currentCalculation = calculation
}

export const setCurrentForecastInfo = (state: State, forecastInfo: ForecastInfo) => {
  state.currentForecastInfo = forecastInfo
}

export const setCurrentCalculationPageData = (state: State, currentCalculationPageData: any) => {
  state.currentCalculationPageData = currentCalculationPageData
}

export const setCalculations = (state: State, calculations: Calculation[]) => {
  state.calculations = calculations
}

export const setStatistics = (state: State, statistics: Statistic[]) => {
  state.statistics = statistics
}

export const setEMFMasterdata = (state: State, items: EMFMasterdata[]) => {
  state.emfMasterdataList = items
}

export const setExcelMappingdata = (state: State, items: EMFMasterdata[]) => {
  state.excelMappingList = items
}

export const setForecasts = (state: State, forecasts: Forecast[]) => {
  state.forecasts = forecasts
}

export const setComparisons = (state: State, comparisons: ComparisonOverviewInfo[]) => {
  state.comparisons = comparisons
}
export const setAccesses = (state: State, accesses: AccessInfo[]) => {
  state.accesses = accesses
}
export const setGivenAccesses = (state: State, givenAccesses: AccessInfo[]) => {
  state.givenAccesses = givenAccesses
}

export const setCurrentAccess = (state: State, access: AccessInfo) => {
  state.currentAccess = access
}

export const setThirdpartyInfo = (state: State, thirdpartyInfo: ThirdpartyInfo) => {
  state.thirdpartyInfo = thirdpartyInfo
}

export const setContentfulEntries = (state: State, contentfulEntries: Map<string, object>): void => {
  state.contentfulEntries = contentfulEntries
}

export const setContentfulContent = (state: State, contentfulContent: ContentfulContent): void => {
  state.contentfulContent = contentfulContent
}

export const setRegexPatterns = (state: State, regexPatterns: Map<string, string>): void => {
  state.regexPatterns = regexPatterns
}

export const setMenuStructure = (state: State, menuStructure: MenuStructure): void => {
  state.menuStructure = menuStructure
}

export const setPendingForecasts = (state: State, entries: PendingForecastEntry[]): void => {
  state.pendingForecasts = entries
}