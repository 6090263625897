import { User } from 'oidc-client'

export class Ident {

    getIdent(user : User) {
        if (user.profile['dk:gov:cvr']) {
            return user.profile['dk:gov:cvr']
        }
        if (user.profile['dk:gov:cpr']) {
            return user.profile['dk:gov:cpr']
        }
        if (user.profile['dk:gov:virk:email']) {
            return  user.profile['dk:gov:virk:email']
        }
        return ''
    }
}