<template>
  <div class='container px-0 py-9' v-if="pageAccess">
    <h1>Misc - tools for developers</h1>
    <form @submit.prevent="getDummy">
      <fieldset>
        <legend>Værktøjer for udviklere</legend>
        <div class='row'>
          <div class='col-md-2 form-button'>
            <!--button id='submit-button-forecast' type='submit' class='button button-secondary adjust-button-size'
                    role='button' @click="recalculateOwn()">Genberegn egne kladde beregninger (kan tage tid)
            </button-->
            <button id='submit-button-forecast' type='submit' class='button button-secondary adjust-button-size'
                    role='button' @click="exportEMF()">Exporter EMF stamdata
            </button>
            <button id='submit-button-forecast' type='submit' class='button button-secondary adjust-button-size'
                    role='button' @click="exportEMCToEMFMapping()">Exporter EMC stamdata
            </button>
            <!--button id='submit-button-forecast' type='submit' class='button button-tertiary'
                    role='button' >Exporter alle beregninger til Excel</button-->
            <!--button id='submit-button-forecast' type='submit' class='button button-tertiary'
                    role='button' @click="exportCalculationAsSql()">Exporter beregning som sql
            </button-->
            <button id='submit-button-forecast' type='submit' class='button button-tertiary'
                    role='button' @click="exportCalculationAsJson()">Exporter beregning som json
            </button>
            <!--button id='submit-button-forecast' type='submit' class='button button-secondary adjust-button-size'
                    role='button' @click="aiString()">AI
            </button-->
          </div>
        </div>
        <div v-if="recalculateErrors.length > 0">
          <p></p>
          {{ recalculateErrors }}
        </div>
        <div>
          <label for="form-input-2">Tabel:</label>
          <input type="text" id="form-input-2" class="form-control" v-model="tableName" placeholder="notification">&nbsp;
          <button class="button button-secondary adjust-button-size" @click="loadTabledata">Hent tabeldata</button>
        </div>

        <div id="rowdata" class="py-9">
          <div class="table--responsive-scroll" style="max-height: 550px; min-width: 680px; overflow: auto;">
            <table class="table" id="row-data-table">
              <caption>Tabel data</caption>
              <thead>
              <tr>
                <th scope="col">Row data #{{ rows.length }}</th>
              </tr>
              </thead>
              <tbody v-if="rows">
              <tr v-for="(item, index) in rows" :key="`item_${item}_${index}`">
                <td>{{ item.value }}</td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr aria-colspan="5">
                <td colspan="5">Ingen rækker at vise</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
  <div class="container px-0 py-9" id="wrapper-div" v-if="pageAccess">
    <h2>Import af beregning fra json fil</h2>
    <p>Hvis der kommer timeout fejl fra server så forsætter BE alligevel. Følg med status under 'Afventende import filer (refresh for opdateret status)'</p>
    <div class="col-md-3" style="padding-top: 20px; padding-bottom: 20px">
      <button style="display:block;width:300px; height:30px;" onclick="document.getElementById('upload-file2').click()">
        Vælg en jsonfil for import
      </button>
      <input type='file' id="upload-file2" style="display:none" @change="addFile">
    </div>
    {{ this.jsonFileName.fileName }}
    <div class="d-flex justify-content-center mt-4">
      <button class="button button-secondary adjust-button-size" @click="importFile">Importer</button>
      <button class="button button-secondary adjust-button-size" @click="refreshFile">Refresh</button>
    </div>

    <div v-if="validationErrors.length > 0">
      <h2 v-if="this.jsonFileName.fileName">Import Log ({{ validationErrors.length }}) </h2>
      <h2 v-else>Validerings Log</h2>
      <div class="table&#45;&#45;responsive-scroll" style="height: 550px; overflow: auto">
        <table class="table w-percent-100">
          <caption></caption>
          <thead>
          <tr>
            <th id="excel-file-name" colspan="5" v-if="this.jsonFileName.fileName" style="text-align: center;">
              {{ this.jsonFileName.fileName }}
            </th>
          </tr>
          </thead>
          <thead>
          <tr>
            <th style="width: 15%" id="validate_error_type">Type</th>
            <th style="width: 40%" id="validate_error_description">Beskrivelse</th>
            <th style="width: 45%" id="validate_error_value">Værdi</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in validationErrors" :key="item.name">
            <td style="width: 15%">{{ item.name }}</td>
            <td style="width: 40%">{{ item.description }}</td>
            <td style="width: 45%">{{ item.value }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else>
      {{ this.status }}
    </div>
    {{ this.deleteError }}
    <div>
      <h2>Afventende import filer</h2>
      <div class="table&#45;&#45;responsive-scroll" style="height: 550px; overflow: auto">
        <table class="table w-percent-100">
          <thead>
          <tr>
            <th>id</th>
            <th>Json calc id</th>
            <th>Beregningsnavn</th>
            <th>Filnavn</th>
            <th>Rid</th>
            <th>CVR</th>
            <th>Status</th>
            <th>Oprettet</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in pendingImportFiles" :key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.json_calculation_id === null ? "Ny" : item.json_calculation_id }}</td>
            <td>{{ item.calculation_name }}</td>
            <td>{{ item.fileName }}</td>
            <td>{{ item.rid }}</td>
            <td>{{ item.vatNo }}</td>
            <td>{{ item.status }}</td>
            <td>{{ item.createdTime }}</td>
          </tr>
          </tbody>
        </table>
        <table class="table w-percent-100">
          <thead>
          <tr>
            <th>Import file id</th>
            <th>Type</th>
            <th>Header</th>
            <th>Description</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in importValidationErrors" :key="item.header">
            <td>{{ item.import_file_id }}</td>
            <td>{{ item.errorType }}</td>
            <td>{{ item.header }}</td>
            <td>{{ item.description }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>


</template>

<script lang='ts'>
import BaseComponent from '@/components/calculator/base-components/BaseComponent'
import * as DKFDS from 'dkfds'
import {
  calculationClient,
  emissionsfactorImportClient,
  statisticsClient,
  aiClient,
  configurationClient, forecastClient
} from '@/shared/BackendService'
import {
  EmissionCoefficientOverview,
  FileMessage, ImportValidationError, PendingImportFile,
  TableRow,
  ValidationError
} from "../../../openapi/generated-clients/climatecompass";
import {downloadFile} from "@/shared/FileHelper";

export default class Misc extends BaseComponent {
  recalculateErrors = ''
  pageAccess = false
  rows: TableRow[] = []
  tableName = 'notification'
  jsonFile = {} as File
  jsonFileName = {} as Partial<FileMessage>
  validationErrors: ValidationError[] = []
  pendingImportFiles: PendingImportFile[] = []
  importValidationErrors: ImportValidationError[] = []
  status = ''


  async addFile(e: Event) {
    const element = e.target as HTMLInputElement
    if (!this.jsonFile) {
      //this.excelFile = {}
    }
    if (!this.jsonFileName) {
      this.jsonFileName = {}
    }
    if (!element.files) return
    this.jsonFile = element.files[0]
    this.jsonFileName.fileName = element.files[0].name
    this.jsonFileName.fileType = element.files[0].type
    element.form?.reset()
    //await this.validateFile()
  }

  async refreshFile() {
    this.getPendingImportFiles()
    this.getValidationErrors()
  }

  async importFile() {
    console.log('Json file : ' + this.jsonFileName.fileName)
    this.status = 'Importere...'
    if (this.jsonFileName.fileName === undefined) {
      window.alert("Der skal vælges en Json fil")
    } else {
      this.validationErrors = (await emissionsfactorImportClient.importJson(this.jsonFile)).data || []
    }
    if (this.validationErrors.length === 0) {
      this.status = "OK. Bemærk at hvis calculation_import:id i json fil matcher allerede exist beregning laves update ellers insert"
    } else {
      this.status = ''
    }
    this.getPendingImportFiles()
    this.getValidationErrors()
  }

  async beforeMount() {
    DKFDS.init()
    this.loadTabledata()
    await configurationClient
        .getImportAccess()
        .then((response) => {
          this.pageAccess = response.data as unknown as boolean
        })
        .catch((error) => {
          console.error(error)
        })
    this.getPendingImportFiles()
    this.getValidationErrors()
}

  updated() {
    DKFDS.init()
  }

  async getDummy() {
    await this.dummy().then(async (cname) => {
      console.log("dummy")
    })
  }

  async dummy() {
    return {}
  }

  recalculateAll() {
    this.recalculateErrors = 'Startet genberegning alle...'
    try {
      calculationClient.recalculateAll().then(((response) => {
            if (response.data) {
              this.recalculateErrors = response.data
            } else {
              this.recalculateErrors = 'Genberegning alle done'
            }
          })
      )
    } catch (error) {
      console.error("Error making API request:", error);
    }
  }

  recalculateOwn() {
    this.recalculateErrors = 'Startet genberegning egne...'
    try {
      calculationClient.recalculateOwn().then(((response) => {
            if (response.data) {
              this.recalculateErrors = response.data
            } else {
              this.recalculateErrors = 'Genberegning egne done'
            }
          })
      )
    } catch (error) {
      console.error("Error making API request:", error);
    }
  }

  async loadTabledata() {
    let result: TableRow[] = []
    this.rows = result
    await configurationClient.getTableRows(this.tableName).then((response) => {
      if (response.data) {
        result = response.data
      }
    })
    return this.rows = result
  }

  async exportEMF() {
    let result = {}

    await emissionsfactorImportClient.exportEMFMasterdata().then((response) => {
      if (response.data) {
        result = response.data
      }
    })
    const win = window.open('', 'EMF sql to export', 'height=600,width=800')
    if (win) {
      win.document.write('<pre>' + result.toString() + '</pre>')
      win.alert('SQL til EMF stamdata, skal typisk puttes i liquibase fil og bygges ind i release')
    }
  }

  async exportEMCToEMFMapping() {
    let result = {}

    await emissionsfactorImportClient.exportEMCToEMFMappingUpdates().then((response) => {
      if (response.data) {
        result = response.data
      }
    })
    const win = window.open('', 'EMC-EMF mapping sql to export', 'height=600,width=800')
    if (win) {
      win.document.write('<pre>' + result.toString() + '</pre>')
      win.alert('SQL til EMC til EMF mapping stamdata, skal typisk puttes i liquibase fil og bygges ind i release')
    }
  }

  async getPendingImportFiles() {
    await emissionsfactorImportClient.pendingImportFiles().then((response) => {
      if (response.data) {
        console.log("sss", response.data)
        this.pendingImportFiles = response.data || []
      }
    })
  }

  async getValidationErrors() {
    console.log("getValidationErrors")
    await emissionsfactorImportClient.importValidationErrors(121308).then((response) => {
      if (response.data) {
        console.log("getValidationErrors data", response.data)
        this.importValidationErrors = response.data || []
      }
    })
  }

  async exportCalculationAsJson() {
    let result = {}

    const name = prompt("Skriv navnet på beregning til export", "");
    if (name) {
      await emissionsfactorImportClient.exportCalculationJson(name).then((response) => {
        if (response.data) {
          result = response.data
        }
      })
      const jsonData = JSON.stringify(result)
      downloadFile(new Blob([jsonData], { type: "application/json" }), name + '.json')
    }
  }

  async exportCalculationAsSql() {
    let result = {}

    const name = prompt("Skriv navnet på beregning til export", "");
    if (name) {
      await emissionsfactorImportClient.exportCalculation(name).then((response) => {
        if (response.data) {
          result = response.data
        }
      })
      const win = window.open('', 'Beregning som sql', 'height=600,width=800')
      if (win) {
        win.document.write('<pre>' + result.toString() + '</pre>')
        win.alert('Beregning')
      }
    }
  }

  async aiString() {
    let result = {}

    const question = prompt("Spørgsmål", "Lastbiler Diesel reduktion af CO2 forslag");
    if (question) {
      await aiClient.getAiAnswer(question).then((response) => {
        if (response.data) {
          result = response.data
        }
      })
      const win = window.open('', 'AI svar', 'height=600,width=800')
      if (win) {
        result.toString().replaceAll('\n', '<br>')
        win.document.write('<p>Spørgsmål:<br>' + question + '<br><br>Svar:<br>' + result.toString().replaceAll('\n', '<br>') + '</p>')
      }
    }
  }

}
</script>

<style scoped>
form {
  margin-top: 24px;
}

.calc-summary li:first-of-type {
  margin-top: 0;
}
</style>